interface IProps {
  uno: string;
  os: string;
}

export const webviewData = () => {
  // const test = 'For more information, see Chapter 3.4.5.1 {"uno":"152999","os":"i"} {"uno":"152999","os":"i"} {"uno":"152999","os":"i"}';
  // const found = test.match(regExp);

  const regExp = /{.*}/g;
  const found = navigator.userAgent.match(regExp);
  const json: IProps = found ? JSON.parse(found![0].split(' ')[0]) : { uno: '0', os: 'web', fkind: 16 };

  return json;
};

export const webviewTitle = (message: { title: string; headerScrollHidden: boolean }) => {
  if ((window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers.storeTitle) {
    (window as any).webkit.messageHandlers.storeTitle.postMessage(message);
  }
};

export const iosPushAlarm = (message: { isAlarm: string; sequence: string }) => {
  if ((window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers.pushAlarm) {
    (window as any).webkit.messageHandlers.pushAlarm.postMessage(message);
  }
};

export const goView = (where: string) => {
  if ((window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers.goView) {
    (window as any).webkit.messageHandlers.goView.postMessage(where); // ios
  }
  (window as any).VogoBridge.goView(where); // aos
};

export const headerGoBack = (message: { title: string }) => {
  if ((window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers.handlerGoBack) {
    (window as any).webkit.messageHandlers.handlerGoBack.postMessage(message); // ios
  }
};
