import React, { memo } from "react";
import styled from "styled-components";
import error from "assets/images/common/ic_error.svg";
import { G4A5561, G858F9C, PVOGO } from "components/shared/common/colors";
import { MEDIUM } from "components/shared/common/fontSize";
import * as S from "components/shared/styles/button.style";
// import { useHistory } from "react-router";
import { checkAgent } from "utils/agent";
import { webviewData } from "utils/webviewData";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const Img = styled.img`
  width: auto;
  max-width: 81px;
  height: auto;
  object-fit: contain;
`;

const Txt = styled.p`
  margin: 50px 0 12px;
  font-size: 22px;
  color: ${G4A5561};
  font-weight: bold;
  text-align: center;
  @media (max-width: 500px) {
    margin: 30px 0 12px;
  }
`;

const TxtSmall = styled.p`
  font-size: ${MEDIUM};
  color: ${G858F9C};
  font-weight: 400;
  line-height: 22px;
  text-align: center;
`;

const BtnBroadcast = styled(S.BtnBroadcast)`
  width: 100%;
  max-width: 100%;
  height: 56px;
  margin-top: 52px;
  margin-right: 0 !important;
  font-size: 17px;
  color: ${G4A5561};
`;

const BtnBroadcastPurple = styled(BtnBroadcast)`
  margin-top: 16px;
  color: ${PVOGO};
`;

const Temporary = memo(() => {
  const navigate = useNavigate();
  const os = checkAgent();
  const webview = webviewData();
  const isAPP = webview.os.indexOf("a") > -1 || webview.os.indexOf("i") > -1;
  const isWeb = !(isAPP && (os === "android" || os === "ios"));

  return (
    <>
      <Container>
        <Content>
          <Img src={error} alt="에러" />
          <Txt>일시적인 오류가 발생했습니다</Txt>
          <TxtSmall>
            요청하신 페이지가 일시적인 오류로
            <br />
            사용 중단되었습니다.
            <br />
            오류가 지속 될 경우, 고객센터로 문의해주세요.
          </TxtSmall>
          {isWeb && (
            <>
              <BtnBroadcast option={"1"} onClick={() => navigate("-1")}>
                이전 페이지로
              </BtnBroadcast>
              <BtnBroadcastPurple option={"3"} onClick={() => navigate("/")}>
                메인 홈으로
              </BtnBroadcastPurple>
            </>
          )}
        </Content>
      </Container>
    </>
  );
});

export default Temporary;
