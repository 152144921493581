import { css } from "styled-components";
import logo from "assets/images/common/logo.svg";
import logoNewGray from "assets/images/common/new_logo_gray.svg";
import logoGray from "assets/images/common/logo_gray.png";
import logoPurple from "assets/images/common/logo_purple.png";
import logoWhite from "assets/images/common/logo_white.png";
import searchWhite from "assets/images/common/ic_search_white.svg";
import searchBlack from "assets/images/common/ic_search_black.svg";
import searchBlack2 from "assets/images/common/ic_search_black_2.svg";
import searchWhiteShadow from "assets/images/common/ic_search_white_shadow.svg";
import searchWhiteShadow2 from "assets/images/common/ic_search_white_shadow_2.svg";
import cartWhiteShadow from "assets/images/common/ic_cart_white_shadow.svg";
import cartWhiteShadow2 from "assets/images/common/ic_cart_white_shadow_2.svg";
import cartWhite from "assets/images/common/ic_cart_white.svg";
import cartBlack from "assets/images/common/ic_cart_black.svg";
import cartBlack2 from "assets/images/common/ic_cart_black_2.svg";
// 기존
import tabHome from "assets/images/common/ic_tab_home.svg";
import tabHomeSelected from "assets/images/common/ic_tab_home_selected.svg";
import tabCategory from "assets/images/common/ic_tab_category.svg";
import tabCategorySelected from "assets/images/common/ic_tab_category_selected.svg";
import tabCart from "assets/images/common/ic_tab_cart.svg";
import tabMy from "assets/images/common/ic_tab_my.svg";
import tabMySelected from "assets/images/common/ic_tab_my_selected.svg";
import tabFeed from "assets/images/common/ic_tab_feed.svg";
import tabFeedSelected from "assets/images/common/ic_tab_feed_selected.svg";
import tabStore from "assets/images/common/ic_tab_store.svg";
import tabStoreSelected from "assets/images/common/ic_tab_store_selected.svg";
// 리뉴얼
import tabHomeNew from "assets/images/common/ic_tab_home_new.svg";
import tabHomeSelectedNew from "assets/images/common/ic_tab_home_selected_new.svg";
import tabStoreNew from "assets/images/common/ic_tab_store_new.svg";
import tabStoreSelectedNew from "assets/images/common/ic_tab_store_selected_new.svg";
import tabFeedNew from "assets/images/common/ic_tab_feed_new.svg";
import tabFeedSelectedNew from "assets/images/common/ic_tab_feed_selected_new.svg";
import tabMyNew from "assets/images/common/ic_tab_my_new.svg";
import tabMySelectedNew from "assets/images/common/ic_tab_my_selected_new.svg";
import live from "assets/images/common/ic_live.png";
import liveTypoLogo from "assets/images/common/ic_live_typo_logo.svg";
import liveViewer from "assets/images/common/ic_live_viewer.svg";
import liveViewer2 from "assets/images/common/ic_live_viewer_2.png";
import liveSchedule from "assets/images/common/ic_live_schedule.svg";
import vodTypoLogo from "assets/images/common/ic_vod_badge.svg";
import arrowNext from "assets/images/common/ic_arrow_next.svg";
import radioOn from "assets/images/common/ic_radio_on.png";
import checkOn from "assets/images/common/ic_check_on.svg";
import arrowExpand from "assets/images/common/ic_arrow_expand.svg";
import cameraPurple from "assets/images/common/ic_camera_purple.svg";
import photoPurple from "assets/images/common/ic_photo_purple.svg";
import micPurple from "assets/images/common/ic_mic_purple.svg";
import downloadWhite from "assets/images/common/ic_download_white.svg";
import downloadGray from "assets/images/common/ic_download_gray.svg";
import downloadPurple from "assets/images/common/ic_download_purple.svg";
import arrowBack from "assets/images/common/ic_arrow_back.svg";
import callBlack from "assets/images/common/ic_call_black.svg";
import callGray from "assets/images/common/ic_call_gray.svg";
import callPurple from "assets/images/common/ic_call_purple.svg";
import eyes from "assets/images/common/ic_eyes.svg";
import eyesOn from "assets/images/common/ic_eyes_on.svg";
import eyesWhite from "assets/images/common/ic_eyes_white.svg";
import eyesGray from "assets/images/common/ic_eyes_gray_2.svg";

import heart from "assets/images/common/ic_heart.svg";
import sellerAskBlack from "assets/images/common/ic_seller_ask_black.svg";
import checkSquareOn from "assets/images/common/ic_check_square_on.svg";
import sellerCancelBlack from "assets/images/common/ic_seller_cancel_black.svg";
import sellerDeliverBlack from "assets/images/common/ic_seller_deliver_black.svg";
import exchageBlack from "assets/images/common/ic_exchage_black.svg";
import returnBlack from "assets/images/common/ic_return_black.svg";
import sellerDeliverPurple from "assets/images/common/ic_seller_deliver_purple.svg";
import reviewBlack from "assets/images/common/ic_review_black.svg";
import purchasePurple from "assets/images/common/ic_purchase_purple.svg";
import exchagePurple from "assets/images/common/ic_exchage_purple.svg";
import sellerDeliver from "assets/images/common/ic_seller_deliver.svg";
import returnPurple from "assets/images/common/ic_return_purple.svg";
import reviewPurple from "assets/images/common/ic_review_purple.svg";
import reviewWhite from "assets/images/common/ic_review.svg";
import viewGray from "assets/images/common/ic_view_gray.svg";
import viewWhite from "assets/images/common/ic_view_white.svg";
import liveNotice from "assets/images/common/ic_live_notice.svg";

import sellerCallBlack from "assets/images/common/ic_seller_call_black.svg";
import sellerInfoBlack from "assets/images/common/ic_seller_info_black.svg";

import snsApple from "assets/images/common/sns_apple.svg";
import snsGoogle from "assets/images/common/sns_google.svg";
import snsKakao from "assets/images/common/sns_kakao.png";
import snsNaver from "assets/images/common/sns_naver.svg";
import arrowSelect from "assets/images/common/ic_arrow_select.svg";
import arrowSelect2 from "assets/images/common/ic_arrow_select2.png";
import ok from "assets/images/common/ic_ok.svg";

import arrowStepPrev from "assets/images/common/ic_arrow_step_prev.svg";
import arrowStepNext from "assets/images/common/ic_arrow_step_next.svg";

import pencil from "assets/images/common/ic_pencil.svg";
import deleteGray from "assets/images/common/ic_delete.svg";

import minusGray from "assets/images/common/ic_minus_gray.svg";
import minusBlack from "assets/images/common/ic_minus_black.svg";
import minusBlack2 from "assets/images/common/ic_minus_black_2.svg";

import plusBlack from "assets/images/common/ic_plus_black.svg";

import homeGray from "assets/images/common/ic_home_gray.svg";
import homePurple from "assets/images/common/ic_home_purple.svg";

import checkWhite from "assets/images/common/ic_check_white.svg";
import plusWhite from "assets/images/common/ic_plus_white.svg";
import plusGray from "assets/images/common/ic_plus_gray.svg";
import plusPurple from "assets/images/common/ic_plus_purple.svg";
import plusPurpleBtn from "assets/images/common/ic_plus_purple_2.svg";

import ArrowWhite from "assets/images/common/ic_arrow_white.svg";
import ArrowGray2 from "assets/images/common/ic_arrow_gray_3.svg";

import checkPurple from "assets/images/common/ic_check_purple.svg";
import info from "assets/images/common/ic_info.svg";
import infoPurple from "assets/images/common/ic_info_purple.svg";

import starOn from "assets/images/common/ic_star_on.svg";
import starOff from "assets/images/common/ic_star_off.svg";
import starBigOn from "assets/images/common/ic_starBig_on.svg";
import starBigOff from "assets/images/common/ic_starBig_off.svg";

import follow from "assets/images/common/ic_follow.svg";
import closeBlack from "assets/images/common/ic_close_black.svg";
import closeCircleGray from "assets/images/common/ic_close_circle_gray.svg";
import closeGray from "assets/images/common/ic_close_gray.svg";
import closeWhite from "assets/images/common/ic_close_white.svg";

import lockGrey from "assets/images/common/ic_lock_grey.svg";
import lockPurple from "assets/images/common/ic_lock_purple.svg";

import share from "assets/images/common/ic_share.svg";
import heartOff from "assets/images/common/ic_heartOff.svg";
import invalidNameGray from "assets/images/common/ic_invalid_name_gray.svg";
import invalidNamePurple from "assets/images/common/ic_invalid_name_purple.svg";

import heartGray2 from "assets/images/common/ic_heart_gray2.svg";

import contentNull from "assets/images/common/content_null.svg";
import contentNull2 from "assets/images/common/content_null2.png";
import exchage from "assets/images/common/ic_exchage.svg";
// import listExpand from 'assets/images/common/ic_list_expand.svg';

import triangleUp from "assets/images/common/ic_triangle_up.svg";
import triangleDown from "assets/images/common/ic_triangle_down.svg";

import chatHeart from "assets/images/common/ic_heart_live.svg";
import chatShare from "assets/images/common/ic_share_live.svg";
import shareBlack from "assets/images/common/ic_share_black.svg";

import chatComment from "assets/images/common/ic_comment_live.svg";
import questionPurple from "assets/images/common/ic_question_purple.svg";
import questionBlack from "assets/images/common/ic_question_black.svg";

import checkGray from "assets/images/common/ic_check_gray.svg";

import assetPurple from "assets/images/common/ic_asset_purple.svg";
import assetGray from "assets/images/common/ic_asset_gray.svg";
import alarmGray from "assets/images/common/ic_alarm_gray_2.svg";

import peopleBgray from "assets/images/common/ic_people_bgray.svg";

import commentWhite from "assets/images/common/ic_comment_white.svg";
import arrowUpBlack from "assets/images/common/ic_arrow_up_black.svg";
import tooltipGary from "assets/images/common/ic_tooltip_gary.svg";
import tabLiveBetaSelected from "assets/images/common/ic_tab_live_beta_selected.svg";
import tabLiveBeta from "assets/images/common/ic_tab_live_beta.svg";

import settings from "assets/images/common/settings.svg";
import Cart_Stroke_24 from "assets/images/common/Cart_Stroke_24.svg";
import Close_Stroke_24 from "assets/images/common/Close_Stroke_24.svg";
import HeaderBack_Stroke_24 from "assets/images/common/HeaderBack_Stroke_24.svg";
import Home_Stroke_24 from "assets/images/common/Home_Stroke_24.svg";
import Question_Stroke_24 from "assets/images/common/Question_Stroke_24.svg";
import Search_Stroke_24 from "assets/images/common/Search_Stroke_24.svg";
import Share03_Stroke_24 from "assets/images/common/Share03_Stroke_24.svg";
import Close_Stroke_18 from "assets/images/common/Close_Stroke_18.svg";
import Group_11 from "assets/images/common/Group_11.svg";
import listContract from "assets/images/common/list_contract.svg";
import listExpand from "assets/images/common/list_expand.svg";
import newWhite from "assets/images/common/ic_new_white.svg";

const icons = css`
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ icon }) => {
    switch (icon) {
      case "logo":
        return `url(${logo})`;
      case "logoNewGray":
        return `url(${logoNewGray})`;
      case "logoGray":
        return `url(${logoGray})`;
      case "logoPurple":
        return `url(${logoPurple})`;
      case "logoWhite":
        return `url(${logoWhite})`;
      case "searchWhite":
        return `url(${searchWhite})`;
      case "searchBlack":
        return `url(${searchBlack})`;
      case "searchBlack2":
        return `url(${searchBlack2})`;
      case "searchWhiteShadow":
        return `url(${searchWhiteShadow})`;
      case "searchWhiteShadow2":
        return `url(${searchWhiteShadow2})`;
      case "cartWhiteShadow":
        return `url(${cartWhiteShadow})`;
      case "cartWhiteShadow2":
        return `url(${cartWhiteShadow2})`;
      case "cartWhite":
        return `url(${cartWhite})`;
      case "cartBlack":
        return `url(${cartBlack})`;
      case "cartBlack2":
        return `url(${cartBlack2})`;
      // 기존
      case "tabHome":
        return `url(${tabHome})`;
      case "tabHomeSelected":
        return `url(${tabHomeSelected})`;
      case "tabCategory":
        return `url(${tabCategory})`;
      case "tabCategorySelected":
        return `url(${tabCategorySelected})`;
      case "tabCart":
        return `url(${tabCart})`;
      case "tabMy":
        return `url(${tabMy})`;
      case "tabMySelected":
        return `url(${tabMySelected})`;
      case "tabFeed":
        return `url(${tabFeed})`;
      case "tabFeedSelected":
        return `url(${tabFeedSelected})`;
      case "tabStore":
        return `url(${tabStore})`;
      case "tabStoreSelected":
        return `url(${tabStoreSelected})`;
      // 리뉴얼
      case "tabHomeNew":
        return `url(${tabHomeNew})`;
      case "tabHomeSelectedNew":
        return `url(${tabHomeSelectedNew})`;

      // case 'tabStoreNew':
      //   return `url(${tabStoreNew})`;
      // case 'tabStoreSelectedNew':
      //   return `url(${tabStoreSelectedNew})`;

      case "tabFeedNew":
        return `url(${tabFeedNew})`;
      case "tabFeedSelectedNew":
        return `url(${tabFeedSelectedNew})`;
      case "tabMyNew":
        return `url(${tabMyNew})`;
      case "tabMySelectedNew":
        return `url(${tabMySelectedNew})`;

      case "tabStoreNew":
        return `url(${tabStoreNew})`;
      case "tabStoreSelectedNew":
        return `url(${tabStoreSelectedNew})`;

      //
      case "live":
        return `url(${live})`;
      case `liveTypoLogo`:
        return `url(${liveTypoLogo})`;
      case `vodTypoLogo`:
        return `url(${vodTypoLogo})`;
      case "liveViewer":
        return `url(${liveViewer})`;
      case "liveViewer2":
        return `url(${liveViewer2})`;
      case "liveSchedule":
        return `url(${liveSchedule})`;
      case "cameraPurple":
        return `url(${cameraPurple})`;
      case "photoPurple":
        return `url(${photoPurple})`;
      case "micPurple":
        return `url(${micPurple})`;
      case "ok":
        return `url(${ok})`;
      case "pencil":
        return `url(${pencil})`;
      case "heart":
        return `url(${heart})`;
      case "heartOff":
        return `url(${heartOff})`;
      case "info":
        return `url(${info})`;
      case "infoPurple":
        return `url(${infoPurple})`;
      case "sellerAskBlack":
        return `url(${sellerAskBlack})`;
      case "sellerCancelBlack":
        return `url(${sellerCancelBlack})`;
      case "sellerDeliverBlack":
        return `url(${sellerDeliverBlack})`;
      case "exchageBlack":
        return `url(${exchageBlack})`;
      case "returnBlack":
        return `url(${returnBlack})`;
      case "sellerDeliverPurple":
        return `url(${sellerDeliverPurple})`;
      case "reviewBlack":
        return `url(${reviewBlack})`;
      case "purchasePurple":
        return `url(${purchasePurple})`;
      case "exchagePurple":
        return `url(${exchagePurple})`;
      case "sellerDeliver":
        return `url(${sellerDeliver})`;
      case "returnPurple":
        return `url(${returnPurple})`;
      case "reviewPurple":
        return `url(${reviewPurple})`;
      case "reviewWhite":
        return `url(${reviewWhite})`;
      case "viewGray":
        return `url(${viewGray})`;
      case "viewWhite":
        return `url(${viewWhite})`;
      case "sellerCallBlack":
        return `url(${sellerCallBlack})`;
      case "sellerInfoBlack":
        return `url(${sellerInfoBlack})`;
      case "lockGrey":
        return `url(${lockGrey})`;
      case "lockPurple":
        return `url(${lockPurple})`;
      case "questionPurple":
        return `url(${questionPurple})`;
      case "questionBlack":
        return `url(${questionBlack})`;
      case "alarmGray":
        return `url(${alarmGray})`;
      case "liveNotice":
        return `url(${liveNotice})`;

      case "peopleBgray":
        return `url(${peopleBgray})`;

      case "share":
        return `url(${share})`;
      case "shareBlack":
        return `url(${shareBlack})`;

      case "invalidNameGray":
        return `url(${invalidNameGray})`;
      case "invalidNamePurple":
        return `url(${invalidNamePurple})`;

      // Download
      case "downloadWhite":
        return `url(${downloadWhite})`;
      case "downloadGray":
        return `url(${downloadGray})`;
      case "downloadPurple":
        return `url(${downloadPurple})`;

      // Call
      case "callBlack":
        return `url(${callBlack})`;
      case "callGray":
        return `url(${callGray})`;
      case "callPurple":
        return `url(${callPurple})`;

      // Arrow
      case "arrowNext":
        return `url(${arrowNext})`;
      case "arrowExpand":
        return `url(${arrowExpand})`;
      case "arrowBack":
        return `url(${arrowBack})`;
      case "arrowSelect":
        return `url(${arrowSelect})`;
      case "arrowSelect2":
        return `url(${arrowSelect2})`;
      case "arrowStepPrev":
        return `url(${arrowStepPrev})`;
      case "arrowStepNext":
        return `url(${arrowStepNext})`;
      case "ArrowWhite":
        return `url(${ArrowWhite})`;
      case "ArrowGray2":
        return `url(${ArrowGray2})`;
      case "exchage":
        return `url(${exchage})`;
      // case 'listExpand':
      //   return `url(${listExpand})`;
      case "triangleUp":
        return `url(${triangleUp})`;
      case "triangleDown":
        return `url(${triangleDown})`;

      // Radio
      case "radioOn":
        return `url(${radioOn})`;
      case "checkOn":
        return `url(${checkOn})`;
      case "checkSquareOn":
        return `url(${checkSquareOn})`;

      // eyes
      case "eyes":
        return `url(${eyes})`;
      case "eyesOn":
        return `url(${eyesOn})`;
      case "eyesWhite":
        return `url(${eyesWhite})`;
      case "eyesGray":
        return `url(${eyesGray})`;

      // SNS
      case "snsApple":
        return `url(${snsApple})`;
      case "snsGoogle":
        return `url(${snsGoogle})`;
      case "snsKakao":
        return `url(${snsKakao})`;
      case "snsNaver":
        return `url(${snsNaver})`;

      //delete
      case "deleteGray":
        return `url(${deleteGray})`;

      //count
      case "minusGray":
        return `url(${minusGray})`;
      case "minusBlack":
        return `url(${minusBlack})`;
      case "minusBlack2":
        return `url(${minusBlack2})`;
      case "plusBlack":
        return `url(${plusBlack})`;
      case "plusGray":
        return `url(${plusGray})`;
      case "plusPurple":
        return `url(${plusPurple})`;
      case "plusPurpleBtn":
        return `url(${plusPurpleBtn})`;

      //home
      case "homeGray":
        return `url(${homeGray})`;
      case "homePurple":
        return `url(${homePurple})`;

      //팔로우,팔로잉
      case "checkWhite":
        return `url(${checkWhite})`;
      case "plusWhite":
        return `url(${plusWhite})`;
      case "follow":
        return `url(${follow})`;

      // check
      case "checkPurple":
        return `url(${checkPurple})`;
      case "assetPurple":
        return `url(${assetPurple})`;
      case "assetGray":
        return `url(${assetGray})`;
      case "checkGray":
        return `url(${checkGray})`;

      // close
      case "closeBlack":
        return `url(${closeBlack})`;
      case "closeGray":
        return `url(${closeGray})`;
      case "closeCircleGray":
        return `url(${closeCircleGray})`;
      case "closeWhite":
        return `url(${closeWhite})`;

      // Star
      case "starOn":
        return `url(${starOn})`;
      case "starOff":
        return `url(${starOff})`;
      case "starBigOn":
        return `url(${starBigOn})`;
      case "starBigOff":
        return `url(${starBigOff})`;

      // Null
      case "contentNull":
        return `url(${contentNull})`;
      case "contentNull2":
        return `url(${contentNull2})`;

      // chat
      case "chatHeart":
        return `url(${chatHeart})`;
      case "chatShare":
        return `url(${chatShare})`;
      case "chatComment":
        return `url(${chatComment})`;
      case "heartGray2":
        return `url(${heartGray2})`;
      case "commentWhite":
        return `url(${commentWhite})`;
      case "arrowUpBlack":
        return `url(${arrowUpBlack})`;
      case "tooltipGary":
        return `url(${tooltipGary})`;

      // new icons
      case "settings":
        return `url(${settings})`;
      case "Cart_Stroke_24":
        return `url(${Cart_Stroke_24})`;
      case "Close_Stroke_24":
        return `url(${Close_Stroke_24})`;
      case "HeaderBack_Stroke_24":
        return `url(${HeaderBack_Stroke_24})`;
      case "Home_Stroke_24":
        return `url(${Home_Stroke_24})`;
      case "Question_Stroke_24":
        return `url(${Question_Stroke_24})`;
      case "Search_Stroke_24":
        return `url(${Search_Stroke_24})`;
      case "Share03_Stroke_24":
        return `url(${Share03_Stroke_24})`;
      case "Close_Stroke_18":
        return `url(${Close_Stroke_18})`;
      case "Group_11":
        return `url(${Group_11})`;
      case "listContract":
        return `url(${listContract})`;
      case "listExpand":
        return `url(${listExpand})`;
      case `LiveBeta`:
        return `url(${tabLiveBeta})`;
      case `LiveBetaSelected`:
        return `url(${tabLiveBetaSelected})`;
      case `newWhite`:
        return `url(${newWhite})`;
      default:
        console.log(`Sorry, ${icon}.`);
    }
  }};
`;

export default icons;
