import React from "react";
import styled from "styled-components";
import { ReactComponent as BackIcon } from "assets/images/pos/ic_backbutton.svg";
import GhostButton from "components/common/ghostButton";

type keyPadProps = {
  onNumberKeyBtnClickHandler: (e: React.MouseEvent) => void;
  onClearButtonHandler: (e: React.MouseEvent) => void;
  BackButtonHandler: (e: React.MouseEvent) => void;
};

const keyPad = ({
  onNumberKeyBtnClickHandler,
  onClearButtonHandler,
  BackButtonHandler,
}: keyPadProps) => {
  return (
    <NumberKeyWrap>
      <NumberKeyRow>
        <NumberKeyButton
          onClick={(e) => onNumberKeyBtnClickHandler(e)}
          value={1}
          width={"9rem"}
          height={"6.8rem"}
          $buttontextsize={"2.8rem"}
          buttonText={"1"}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
        <NumberKeyButton
          onClick={(e) => onNumberKeyBtnClickHandler(e)}
          value={2}
          width={"9rem"}
          height={"6.8rem"}
          $buttontextsize={"2.8rem"}
          buttonText={"2"}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
        <NumberKeyButton
          onClick={(e) => onNumberKeyBtnClickHandler(e)}
          value={3}
          width={"9rem"}
          height={"6.8rem"}
          $buttontextsize={"2.8rem"}
          buttonText={"3"}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
      </NumberKeyRow>
      <NumberKeyRow>
        <NumberKeyButton
          onClick={(e) => onNumberKeyBtnClickHandler(e)}
          value={4}
          width={"9rem"}
          height={"6.8rem"}
          $buttontextsize={"2.8rem"}
          buttonText={"4"}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
        <NumberKeyButton
          onClick={(e) => onNumberKeyBtnClickHandler(e)}
          value={5}
          width={"9rem"}
          height={"6.8rem"}
          $buttontextsize={"2.8rem"}
          buttonText={"5"}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
        <NumberKeyButton
          onClick={(e) => onNumberKeyBtnClickHandler(e)}
          value={6}
          width={"9rem"}
          height={"6.8rem"}
          $buttontextsize={"2.8rem"}
          buttonText={"6"}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
      </NumberKeyRow>
      <NumberKeyRow>
        <NumberKeyButton
          onClick={(e) => onNumberKeyBtnClickHandler(e)}
          value={7}
          width={"9rem"}
          height={"6.8rem"}
          $buttontextsize={"2.8rem"}
          buttonText={"7"}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
        <NumberKeyButton
          onClick={(e) => onNumberKeyBtnClickHandler(e)}
          value={8}
          width={"9rem"}
          height={"6.8rem"}
          $buttontextsize={"2.8rem"}
          buttonText={"8"}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
        <NumberKeyButton
          onClick={(e) => onNumberKeyBtnClickHandler(e)}
          value={9}
          width={"9rem"}
          height={"6.8rem"}
          $buttontextsize={"2.8rem"}
          buttonText={"9"}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
      </NumberKeyRow>
      <NumberKeyRow>
        <NumberKeyButton
          onClick={onClearButtonHandler}
          width={"9rem"}
          height={"6.8rem"}
          buttonText={"C"}
          $buttontextsize={"2.8rem"}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
        <NumberKeyButton
          onClick={(e) => onNumberKeyBtnClickHandler(e)}
          value={0}
          width={"9rem"}
          height={"6.8rem"}
          $buttontextsize={"2.8rem"}
          buttonText={"0"}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
        <NumberKeyButton
          onClick={BackButtonHandler}
          width={"9rem"}
          height={"6.8rem"}
          icon={<BackIcon />}
          type={"enabled"}
          $padding={"1.6rem 2rem"}
        />
      </NumberKeyRow>
    </NumberKeyWrap>
  );
};

export default keyPad;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  z-index: 100;
  margin-bottom: 2.1rem;
`;

// 키패드
const NumberKeyWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  z-index: 100;
  margin-bottom: 2.1rem;
`;

const NumberKeyRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
  gap: 0.2rem;
  cursor: pointer;
`;

const NumberKeyButton2 = styled.button`
  display: flex;
  width: 9rem;
  height: 6.8rem;
  padding: 1.6rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #4a5561;
  text-align: center;

  /* Headline/Headline_28_R */
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 128.571% */
  letter-spacing: -0.5px;

  border-radius: 10px;
  background: #f1f3f5;

  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const NumberKeyButton = styled(GhostButton)``;
