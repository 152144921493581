import React, {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import {useAuthContext} from "store/context/authContext";
import {removeLocalStorage} from "utils/storage";
import {isTokenExpiringSoon} from "utils/token";

export const ProtectedRoute = ({children}) => {
  const {accessToken, refreshToken} = useAuthContext();
  const [isExpired, setIsExpired] = useState(false); // 초기 상태를 false로 설정

  useEffect(() => {
    // refreshToken 변경될 때마다 만료 여부를 확인
    setIsExpired(isTokenExpiringSoon(refreshToken));
  }, [refreshToken]); // accessToken을 의존성 목록에 추가

  // 토큰이 없거나 만료된 경우 로그인 페이지로 리디렉션
  if (!refreshToken || isExpired) {
    removeLocalStorage(accessToken);
    removeLocalStorage(refreshToken);
    return <Navigate to="/"/>;
  }

  return children;
};
