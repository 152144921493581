import React, { memo } from "react";
import styled from "styled-components";
import error from "assets/images/common/ic_error_2.png";
import { G4A5561, G858F9C, PVOGO } from "components/shared/common/colors";
import { MEDIUM } from "components/shared/common/fontSize";
import * as S from "components/shared/styles/button.style";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100vh;
  @media (max-width: 320px) {
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const Img = styled.img`
  width: 100%;
  max-width: 279px;
  height: auto;
  object-fit: contain;
  @media (max-width: 375px) {
    width: 74%;
    max-width: 74%;
  }
`;

const Txt = styled.p`
  margin: 50px 0 12px;
  font-size: 22px;
  color: ${G4A5561};
  font-weight: bold;
  text-align: center;
`;

const TxtSmall = styled.p`
  font-size: ${({ theme }) => theme.fontSizePx.MEDIUM};
  color: ${G858F9C};
  font-weight: 400;
  line-height: 22px;
  text-align: center;
`;

const BtnBroadcast = styled(S.BtnBroadcast)`
  width: 100%;
  max-width: 100%;
  height: 56px;
  margin-top: 52px;
  margin-right: 0 !important;
  font-size: 17px;
  color: ${G4A5561};
`;

const BtnBroadcastPurple = styled(BtnBroadcast)`
  margin-top: 16px;
  color: ${PVOGO};
`;

const NotFound = memo(() => {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <Content>
          <Img src={error} alt="에러" />
          <Txt>요청한 페이지를 찾을 수 없습니다</Txt>
          <TxtSmall>
            주소가 잘못 입력되었거나, 변경/삭제되었습니다.
            <br />
            입력하신 주소를 확인하시거나 재접속 해주세요.
          </TxtSmall>
          <BtnBroadcast option={"1"} onClick={() => navigate(-2)}>
            이전 페이지로
          </BtnBroadcast>
          <BtnBroadcastPurple option={"3"} onClick={() => navigate("/")}>
            메인 홈으로
          </BtnBroadcastPurple>
        </Content>
      </Container>
    </>
  );
});

export default NotFound;
