import { PRETENDARD } from "components/shared/common/fontFamily";
import React, { useState } from "react";
import styled, { css } from "styled-components";

export type FormButtonState = "enabled" | "disabled" | "pressed";

export type FormButtonProps = {
  onClick: (e: React.MouseEvent) => void;
  buttonText: string;
};

export type FormButtonStyle = {
  width?: string;
  height?: string;
  buttonTextSize?: string;
  type: FormButtonState;
};

const FormButton = ({
  width,
  height,
  buttonText,
  buttonTextSize,
  type,
  onClick,
}: FormButtonProps & FormButtonStyle) => {
  const [buttonstate, setButtonState] = useState<FormButtonState>(type);

  // 마우스를 누를 때 'pressed' 상태로 변경
  const handleMouseDown = (e: React.MouseEvent) => {
    setButtonState("pressed");
  };

  // 마우스를 놓을 때 'enabled' 상태로 복원
  const handleMouseUp = (e: React.MouseEvent) => {
    setButtonState("enabled");
  };

  return (
    <Button
      width={width}
      height={height}
      type={buttonstate}
      buttonTextSize={buttonTextSize}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

export default FormButton;

const Button = styled.button<FormButtonStyle>`
  display: flex;
  padding: 1.6rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 10px;
  font-weight: 700;
  font-family: ${PRETENDARD};
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  letter-spacing: -0.4px;
  font-size: ${({ buttonTextSize }) =>
    buttonTextSize ? buttonTextSize : "15px"};
  width: ${({ width }) => (width ? width : "40rem")};
  height: ${({ height }) => (height ? height : "5.2rem")};
  ${({ type }) => FormButtonStateStyle(type)}
`;

const FormButtonStateStyle = (type: FormButtonState) => {
  let BoxShadow = "";
  let Background = "";
  let Color = "";
  switch (type) {
    case "enabled":
      BoxShadow = "0px 6px 15px 0px rgba(103, 40, 255, 0.20)";
      Background = "#6728FF";
      Color = "#fff";
      break;
    case "disabled":
      BoxShadow = "";
      Background = "#F8F9FA";
      Color = "#CCD4D8";
      break;
    case "pressed":
      BoxShadow = "0px 3px 5px 0px rgba(103, 40, 255, 0.20)";
      Background = "#521FCF";
      Color = "#fff";
      break;
    default:
      BoxShadow = "";
      Background = "#F8F9FA";
      break;
  }
  return css`
    box-shadow: ${BoxShadow};
    background: ${Background};
    color: ${Color};
  `;
};
