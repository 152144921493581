import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "components/common/modal";
import { useAuthContext } from "store/context/authContext";
import { posHotplpassApi } from "services/posHotplpassApi";
import Button from "components/common/Button";
import { replaceMiddleWithAsterisk } from "utils/regExp";
import { addSpaceEveryFourChars } from "utils/textFormatting";

type OrderDetailModalProps = {
  orderModalSee: boolean;
  setOrderModalSee: any;
  orderItemCode: string;
  orderItemIdx: number;
  setIsToastOpen: React.Dispatch<boolean>;
  setToastText: React.Dispatch<string>;
};

const OrderDetailModal = ({
  orderModalSee,
  setOrderModalSee,
  orderItemCode,
  orderItemIdx,
  setIsToastOpen,
  setToastText,
}: OrderDetailModalProps) => {
  const { branchName } = useAuthContext();
  const [orderDetail, setOrderDetail] = useState<any>({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);

  const fetchHotplInfo = async (orderItemCode: string) => {
    try {
      const result = await posHotplpassApi.getHotplpassOrderDetail(
        orderItemIdx,
        orderItemCode
      );
      const { data, meta } = result.data;
      if (meta.code === 0) {
        const orderTime = data.date.split("T");
        setOrderDetail({
          ...data,
          date: orderTime[0] + " " + orderTime[1],
        });
        setIsDataLoaded(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const putRefund = async () => {
    try {
      const result = await posHotplpassApi.putHotplpassRefund(
        orderItemIdx,
        orderItemCode
      );
      const { data, meta } = result.data;
      if (meta.code === 0) {
        setOrderModalSee(false);
        setIsToastOpen(true);
        setToastText("환불이 완료되었습니다.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (orderItemCode !== "") {
      setIsDataLoaded(false);
      fetchHotplInfo(orderItemCode);
    }
  }, []);

  const onCloseOrderModalHandler = () => {
    setOrderModalSee(false);
    setOrderDetail({});
  };

  const onPriceHandler = (price: number) => {
    return Number(price).toLocaleString("en-US");
  };

  return (
    <Wrapper>
      {isDataLoaded && orderDetail && (
        <Dim isOpen={orderModalSee}>
          <SModal>
            <ModalWrapper>
              <OrderDetailWrapper>
                <OrderDetailTitleWrapper>
                  <BranchNameTitle>{branchName}</BranchNameTitle>
                  <Price>
                    {Number(orderDetail.price).toLocaleString("en-US")}원
                  </Price>
                </OrderDetailTitleWrapper>
                <Line />
                <ListItemWrapper>
                  <RowWrapper>
                    <Label>승인일시</Label>
                    <Item>{orderDetail.date}</Item>
                  </RowWrapper>
                  <RowWrapper>
                    <Label>코드번호</Label>
                    <Item>{addSpaceEveryFourChars(orderDetail.code)}</Item>
                  </RowWrapper>
                  <RowWrapper>
                    <Label>거래금액</Label>
                    <Item>{onPriceHandler(orderDetail.price)}원</Item>
                  </RowWrapper>
                  <RowWrapper>
                    <Label>거래유형</Label>
                    {orderDetail.type === "USE" && <Item>결제완료</Item>}
                    {orderDetail.type === "REFUND" && <Item2>결제취소</Item2>}
                  </RowWrapper>
                  <DotLine />
                  <RowWrapper>
                    <Label>가맹점명</Label>
                    <Item>{branchName}</Item>
                  </RowWrapper>
                  <RowWrapper>
                    <Label>사용고객</Label>
                    <Item>
                      {replaceMiddleWithAsterisk(orderDetail.userName)}
                    </Item>
                  </RowWrapper>
                </ListItemWrapper>
              </OrderDetailWrapper>
              <ButtonWrapper>
                {orderDetail.type === "USE" && (
                  <>
                    <CloseButton onClick={() => setShowRefundModal(true)}>
                      환불
                    </CloseButton>

                    <Button
                      buttonText={"닫기"}
                      onClick={onCloseOrderModalHandler}
                      type="enabled"
                      width="18.8rem"
                      height="6.1rem"
                    />
                  </>
                )}
                {orderDetail.type === "REFUND" && (
                  <>
                    <Button
                      buttonText={"닫기"}
                      onClick={onCloseOrderModalHandler}
                      type="enabled"
                      width="100%"
                      height="6.1rem"
                    />
                  </>
                )}
              </ButtonWrapper>
            </ModalWrapper>
          </SModal>
        </Dim>
      )}
      {showRefundModal && (
        <Modal
          titleText={"환불을 진행하시겠습니까?"}
          subText={
            "한 번 더 확인해주세요.</br>환불 시 해당 거래 내역을 되돌릴 수 없습니다."
          }
          enableButtonText={"환불하기"}
          width={"44rem"}
          height={"24.4rem"}
          showModal={showRefundModal}
          setShowModal={setShowRefundModal}
          textGap={"2rem"}
          onClickHandler={putRefund}
        />
      )}
    </Wrapper>
  );
};

export default OrderDetailModal;

const Wrapper = styled.div``;

const ModalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const OrderDetailWrapper = styled.div`
  margin-bottom: 8rem;
`;

const OrderDetailTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`;

const Dim = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const CloseButton = styled.button`
  display: flex;
  width: 10.8rem;
  height: 6.1rem;
  padding: 1.6rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;

  color: #4a5561;
  text-align: center;

  /* Body/Body_16_R */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 150% */
  letter-spacing: -0.2px;
  border-radius: 10px;
  background: #f1f3f5;
`;

const BranchNameTitle = styled.p`
  color: #343a40;

  /* Body/Body_18_B */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.2px;
`;

const Price = styled.p`
  color: #343a40;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 122.222%; /* 122.222% */
  letter-spacing: -0.5px;
`;

const Line2 = styled.hr`
  width: 100%;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 1px;
  stroke: #343a40;
  border-style: dotted;
  border-width: 0.1rem;
`;

const Line = styled.hr`
  width: 100%;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: #343a40;
`;

const DotLine = styled.hr`
  width: 100%;
  height: 0px;
  flex-shrink: 0;
  border-top: 1px dashed #343a40;
`;

export const SModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1100;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 36rem;
  height: 49rem;
  background: white;
  padding: 4rem 2.8rem 3.8rem;

  display: flex;
  flex-direction: column;
`;

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.1rem;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Label = styled.h5`
  color: var(--Gray-Gray06_SubText, #858f9c);
  /* Body/Body_14_R */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
`;

const Item = styled.div`
  color: #343a40;
  text-align: right;

  /* Body/Body_14_R */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
`;

const Item2 = styled.div`
  color: #e02020;
  text-align: right;

  /* Body/Body_14_R */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
`;
