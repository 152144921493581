import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/pos/store`,
  // baseURL: `http://192.168.219.131:8080/api/pos/store`,
  headers: {
    os: process.env.REACT_APP_OS,
    version: process.env.REACT_APP_VERSION,
  },
});

const accessToken = localStorage.getItem("accessToken");
const refreshToken = localStorage.getItem("refreshToken");

export const storeApi = {
  signUp: ({ ownerIdx, id, password, store }) =>
    api.post("/branch/signup", { ownerIdx, id, password, store }), // 지점 가입 요청
  login: ({ id, password }) => api.post("/branch/signin", { id, password }), // 지점 로그인 요청
  // 로그아웃
  deleteLogout: () =>
    api.delete("/branch/signout", { data: { accessToken, refreshToken } }),
};
