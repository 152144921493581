// 날짜형식: 2024-01-01
export const formatDate = (date) => {
  var d = new Date(date);
  var year = d.getFullYear();
  var month = ("0" + (d.getMonth() + 1)).slice(-2);
  var day = ("0" + d.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

// 날짜형식: 2024년 01월 01일
export const formatDate2 = (date) => {
  return date.toLocaleDateString("ko-KR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

// 날짜형식: 01월 01일
export const formatDate3 = (date) => {
  return date.toLocaleDateString("ko-KR", {
    // year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const getKSTElapsedTime = (date) => {
  const kstOffset = 9 * 60 * 60 * 1000; // 한국 시간대의 오프셋 (9시간을 밀리초로 변환)
  const givenTime = new Date(date).getTime(); // 주어진 시간을 밀리초로 변환

  // 한국 시간대에 맞춰 주어진 시간을 조정
  const givenTimeInKST = givenTime + kstOffset;

  // 경과 시간을 밀리초로 계산
  const elapsedTimeInMilliseconds = givenTimeInKST;

  // 밀리초를 다른 단위로 변환
  let seconds = Math.floor(elapsedTimeInMilliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // 시간, 분, 초를 2자리 숫자로 포맷
  seconds %= 60;
  minutes %= 60;
  hours %= 24;

  const time = `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${(
    "0" + seconds
  ).slice(-2)}`;
  return time;
};
