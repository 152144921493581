import { ERROR } from "constants/errCode";
import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { storeApi } from "services/storeApi";
import { isTokenExpiringSoon, postJwtReissue } from "utils/token";
import {sw} from "utils/serviceWorker";

export const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken") || false
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken") || false
  );
  // 자동 로그인
  const [isAutoLogin, setIsAutoLogin] = useState(
    JSON.parse(localStorage.getItem("isAutoLogin")) || true
  );

  const [branchName, setBranchName] = useState("");
  const [loginIdErrMsg, setLoginIdErrMsg] = useState("");
  const [loginPwErrMsg, setLoginPwErrMsg] = useState("");

  const navigate = useNavigate();

  // 자동 로그인 처리
  const autoLogin = async () => {
    // refreshToken이 만료되기 10초 전 이라면 바로 로그인 페이지로 이동
    if (isTokenExpiringSoon(refreshToken)) {
      // refreshToken이 만료되면 로그인 페이지로 이동
      navigate("/");
      return;
    }
    // accessToken 토큰 만료 여부 확인 함수
    if (isTokenExpiringSoon(accessToken)) {
      try {
        // 토큰 리이슈 처리 함수
        const newTokens = await postJwtReissue(accessToken, refreshToken);
        setAccessToken(newTokens.accessToken);
        setRefreshToken(newTokens.refreshToken);
      } catch (reissueError) {
        navigate("/");
        return;
      }
    }
  };

  // 토큰 초기화 - 로컬스토리지에서 불러오기
  useEffect(() => {
    setLoginIdErrMsg("");
    setLoginPwErrMsg("");
    const branchName = localStorage.getItem("branchName");
    if (branchName) {
      setBranchName(branchName);
    }
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setAccessToken(accessToken);
    }
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      setRefreshToken(refreshToken);
    }
    // 컴포넌트 마운트 시 자동 로그인 처리
    if (isAutoLogin) {
      autoLogin();
    }
  }, []);

  // 로그인
  const login = async (formData, autoLoginEnabled) => {
    setAccessToken("");
    setRefreshToken("");
    setBranchName("");
    try {
      const result = await storeApi.login(formData);
      const { meta, data } = result.data;
      if (meta.code === 0) {
        if (data.jwtToken) {
          localStorage.setItem("accessToken", data.jwtToken.accessToken);
          localStorage.setItem("refreshToken", data.jwtToken.refreshToken);
          localStorage.setItem("branchName", data.branchName);
          localStorage.setItem("tagId", data.tagId);
          setAccessToken(data.jwtToken.accessToken);
          setRefreshToken(data.jwtToken.refreshToken);
          setBranchName(data.branchName);
          navigate("/orderList");
        }
        localStorage.setItem("isAutoLogin", JSON.stringify(autoLoginEnabled));
        setIsAutoLogin(autoLoginEnabled);
        sw.subscribe();
      } else {
        alert(meta.message);
      }
    } catch (error) {
      // 오류 처리
      setLoginPwErrMsg("");
      setLoginIdErrMsg("");
      const err = error;
      if (err.response && err.response.data && err.response.data.meta) {
        const { meta } = err.response.data;
        let errIdMsg = "";
        let errPwMsg = "";
        switch (meta.code) {
          case ERROR.STORE_BRANCH_SIGNIN.ID_LENGTH_MAX.CODE:
            errIdMsg = ERROR.STORE_BRANCH_SIGNIN.ID_LENGTH_MAX.MESSAGE;
            break;
          case ERROR.STORE_BRANCH_SIGNIN.ID_LENGTH_MIN.CODE:
            errIdMsg = ERROR.STORE_BRANCH_SIGNIN.ID_LENGTH_MIN.MESSAGE;
            break;
          case ERROR.STORE_BRANCH_SIGNIN.PW_LENGTH_MAX.CODE:
            errPwMsg = ERROR.STORE_BRANCH_SIGNIN.PW_LENGTH_MAX.MESSAGE;
            break;
          case ERROR.STORE_BRANCH_SIGNIN.NO_MATCH_PW.CODE:
            errPwMsg = ERROR.STORE_BRANCH_SIGNIN.NO_MATCH_PW.MESSAGE;
            break;
          case ERROR.STORE_BRANCH_SIGNIN.NO_STORE_BRANCH.CODE:
            errPwMsg = ERROR.STORE_BRANCH_SIGNIN.NO_STORE_BRANCH.MESSAGE;
            break;
          default:
            errPwMsg = meta.message;
            break;
        }
        setLoginIdErrMsg(errIdMsg);
        setLoginPwErrMsg(errPwMsg);
      }
      console.error(error);
    }
  };

  // 회원 가입
  const signUp = async (formData) => {
    try {
      const result = await storeApi.signUp(formData);
      const { meta, data } = result.data;
      if (meta.code === 0) {
        if (data.jwtToken) {
          setAccessToken(data.jwtToken.accessToken);
          setRefreshToken(data.jwtToken.refreshToken);
          localStorage.setItem("accessToken", data.jwtToken.accessToken);
          localStorage.setItem("refreshToken", data.jwtToken.refreshToken);
          navigate("/orderList");
          window.location.reload();
        }
      } else {
        alert(meta.message);
      }
    } catch (error) {
      // 오류 처리
    }
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        setAccessToken,
        refreshToken,
        branchName,
        isAutoLogin,
        login,
        signUp,
        loginIdErrMsg,
        loginPwErrMsg,
        setLoginIdErrMsg,
        setLoginPwErrMsg,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
