import { PRETENDARD } from "components/shared/common/fontFamily";
import React, { useState } from "react";
import styled, { css } from "styled-components";

export type GhostButtonProps = {
  onClick: (e: React.MouseEvent) => void;
  buttonText?: string;
  icon?: any;
  value?: number | string;
};

export type GhostButtonState = "enabled" | "pressed";

export type GhostButtonStyle = {
  width?: string;
  height?: string;
  $buttontextsize?: string;
  $padding?: string;
  gap?: string;
  type: GhostButtonState;
};

const GhostButton = ({
  onClick,
  value,
  width,
  height,
  buttonText,
  $buttontextsize,
  type = "enabled",
  icon,
  $padding,
  gap,
}: GhostButtonProps & GhostButtonStyle) => {
  const [buttonstate, setButtonState] = useState<GhostButtonState>(type);

  // 마우스를 누를 때 'pressed' 상태로 변경
  const handleMouseDown = (e: React.MouseEvent) => {
    setButtonState("pressed");
  };

  // 마우스를 놓을 때 'default' 상태로 복원
  const handleMouseUp = (e: React.MouseEvent) => {
    setButtonState("enabled");
  };

  return (
    <Button
      onClick={onClick}
      value={value}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      $buttontextsize={$buttontextsize}
      type={buttonstate}
      width={width}
      height={height}
      $padding={$padding}
      gap={gap}
    >
      {icon ? icon : buttonText}
    </Button>
  );
};

export default GhostButton;

const Button = styled.button<GhostButtonStyle>`
  display: flex;
  width: ${({ width }) => (width ? width : "8.6rem")};
  height: ${({ height }) => (height ? height : "5.2rem")};
  padding: ${({ $padding }) => ($padding ? $padding : "1.6rem 2rem")};
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  flex-shrink: 0;
  border-radius: 1rem;
  color: #4a5561;
  text-align: center;

  /* Body/Body_14_R */
  font-family: ${PRETENDARD};
  font-size: ${({ $buttontextsize }) =>
    $buttontextsize ? $buttontextsize : "1.4rem"};
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%; /* 20px */
  letter-spacing: -0.2px;
  cursor: pointer;
  ${({ type }) => GhostButtonStateStyle(type)}
`;

const GhostButtonStateStyle = (type: GhostButtonState) => {
  let Background = "";
  switch (type) {
    case "enabled":
      Background = "#f1f3f5";
      break;
    case "pressed":
      Background = "#ACB5BD";
      break;
    default:
      Background = "#f1f3f5";
      break;
  }
  return css`
    background: ${Background};
  `;
};
