import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LoginFormPassword from "components/login/loginFormPassword";
import LoginFormPhone from "components/login/loginFormPhone";

type Props = {};
export type LoginFormType = "password" | "phone";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 3rem;
  margin-bottom: 4rem;
`;

const TabItem = styled.div<{ $active: boolean }>`
  width: 50%;
  text-align: center;
  font-family: Pretendard, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 142.857%; /* 20px*/
  letter-spacing: -0.2px;
  border-bottom: ${({ $active }) =>
    $active
      ? "2px solid #6728ff"
      : "1px solid #dfe5e8"};

  color: ${({ $active }) => ($active ? "#6728ff" : "#acb5bd")};
  font-weight: ${({ $active }) => ($active ? "700" : "400")};
  cursor: pointer;
`;

const LinkWrapper = styled.div`
  color: #858f9c;
  font-family: Pretendard, sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%; /*  16px*/
  letter-spacing: -0.2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 4.8rem;
`;

const LinkItem = styled.div`
  padding: 0 0.8rem;
  cursor: pointer;
`;

const LinkBorder = styled.div`
  width: 1px;
  height: 8px;
  background: #dfe5e8;
`;

const Login = ({}: Props) => {
  const [LoginFormType, setLoginFormType] = useState<LoginFormType>("password");
  const [LoginFormTab, setLoginFormTab] = useState("password");

  return (
    <Wrapper>
      <TabWrapper>
        <TabItem
          id="password"
          $active={LoginFormTab == "password"}
          onClick={() => setLoginFormTab("password")}
        >
          비밀번호
        </TabItem>
        <TabItem
          id="phone"
          $active={LoginFormTab == "phone"}
          onClick={() => setLoginFormTab("phone")}
        >
          휴대폰인증
        </TabItem>
      </TabWrapper>
      {LoginFormTab === "password" && <LoginFormPassword/>}
      {LoginFormTab === "phone" && <LoginFormPhone/>}
      <LinkWrapper>
        <LinkItem>아이디찾기</LinkItem>
        <LinkBorder/>
        <LinkItem>비밀번호찾기</LinkItem>
        <LinkBorder/>
        <LinkItem onClick={e => console.log('회원가입')}>회원가입</LinkItem>
      </LinkWrapper>
    </Wrapper>
  );
};

export default Login;
