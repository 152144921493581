import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { Input, InputStyleType } from "components/common/Input";
import { useAuthContext } from "store/context/authContext";
import FormButton, { FormButtonState } from "components/common/FormButton";
import {
  StyledCheckBoxChecked,
  StyledCheckBoxUnChecked,
} from "components/signUp/signUpForm";

type Props = {};

const LoginFormPassword = ({}: Props) => {
  const [formData, setFormData] = useState({
    id: "",
    password: "",
  });

  const [inputStateData, setInputStateData] = useState<{
    id: InputStyleType;
    password: InputStyleType;
  }>({
    id: "default",
    password: "default",
  });

  const [errorMsg, setErrorMsg] = useState({
    id: "",
    password: "",
  });

  const validateForm = (id: string, password: string) => {
    const idValid = id.length >= 6 && id.length < 20;
    const isPasswordValid = password.length >= 6; // 최소 6글자
    return idValid && isPasswordValid ? "enabled" : "disabled";
  };

  const validateIdLength = (id: string) => {
    const idValid = id.length >= 6 && id.length < 20;
    return idValid ? "enabled" : "disabled";
  };

  const validatePasswordLength = (password: string) => {
    const isPasswordValid = password.length >= 6; // 최소 6글자
    return isPasswordValid ? "enabled" : "disabled";
  };

  const {
    login,
    isAutoLogin,
    loginIdErrMsg,
    loginPwErrMsg,
    setLoginIdErrMsg,
    setLoginPwErrMsg,
  } = useAuthContext();
  const idRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [autoLoginEnabled, setAutoLoginEnabled] = useState(isAutoLogin);
  const [isButtonEnabled, setIsButtonEnabled] = useState<FormButtonState>(
    validateForm(formData.id, formData.password)
  );
  const [idErr, setIdErr] = useState(loginIdErrMsg);
  const [pwErr, setPwErr] = useState(loginPwErrMsg);

  useEffect(() => {
    // formData가 변경될 때마다 실행
    const buttonstate = validateForm(formData.id, formData.password);
    setIsButtonEnabled(buttonstate);
  }, [formData.id, formData.password]);

  // input 이벤트 핸들러
  const handleInput = (e: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  // input의 값을 다루는 함수
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // 폼 데이터 상태 업데이트
    const newFormData = { ...formData, [name]: value };

    if (name == "id") {
      if (value.length >= 6 && value.length < 20) {
        setIdErr("");
      } else {
        setIdErr("아이디를 6자 이상 20자 미만 입력해주세요.");
      }
    }

    if (name == "password") {
      if (value.length >= 6) {
        setPwErr("");
      } else {
        setPwErr("패스워드를 최소 6자 이상 입력해주세요.");
      }
    }

    setFormData(newFormData);

    // 유효성 검사 수행
    const formValidationResult = validateForm(
      newFormData.id,
      newFormData.password
    );

    setIsButtonEnabled(formValidationResult); // 예: setFormValidState("enabled" 또는 "disabled")
    setLoginIdErrMsg("");
    setLoginPwErrMsg("");
    // if (name === "id") {
    //   let emailType = onEmailValidationHandler(value);
    //   if (emailType === "valid") {
    //     setInputStateData({ ...inputStateData, [name]: "default" });
    //   } else {
    //     setInputStateData({ ...inputStateData, [name]: "error" });
    //   }
    // }
    // setFormData({ ...formData, [name]: value });
  };

  // 폼 전송을 담당하는 함수
  const onFormHandler = (e: any) => {
    e.preventDefault();
    const { id, password } = formData;
    setIsButtonEnabled(validateForm(id, password));
    if (id === "") {
      setErrorMsg({ ...errorMsg, id: "아이디를 입력해주세요." });
      setInputStateData({ ...inputStateData, id: "error" });
      return;
    }
    if (password === "") {
      setErrorMsg({ ...errorMsg, password: "비밀번호를 입력해주세요." });
      setInputStateData({ ...inputStateData, password: "error" });
      return;
    }
    const validState = validateForm(formData.id, formData.password);
    if (validState === "enabled") {
      login(formData, autoLoginEnabled);
    }
  };

  const handleEnter = (e: any) => {
    const validState = validateForm(formData.id, formData.password);
    if (e.key === "Enter") {
      if (validState === "enabled") {
        login(formData, autoLoginEnabled);
      }
    }
  };

  useEffect(() => {
    if (idRef && idRef.current) {
      idRef.current.focus();
    }
  }, []);

  return (
    <Wrapper>
      {/* form에서 enter 눌렀을 때 작동하도록 */}
      <Form onSubmit={(e) => e.preventDefault()}>
        {/* id */}
        <Input
          type={"text"}
          name={"id"}
          value={formData.id}
          width={"40rem"}
          height={"5.2rem"}
          onChange={onChangeHandler}
          placeholder={"아이디를 입력해주세요."}
          label={"아이디"}
          required={"required"}
          textAlign={"center"}
          readOnly={false}
          inputStyleType={inputStateData.id}
          errMsg={idErr !== "" ? idErr : loginIdErrMsg}
          minLength={6}
          maxLength={20}
          // ref={idRef}
        />
        {/* password */}
        <Input
          type={"password"}
          name={"password"}
          value={formData.password}
          width={"40rem"}
          height={"5.2rem"}
          onChange={onChangeHandler}
          placeholder={"비밀번호를 입력해주세요."}
          label={"비밀번호"}
          required={"required"}
          textAlign={"center"}
          readOnly={false}
          inputStyleType={inputStateData.password}
          errMsg={pwErr !== "" ? pwErr : loginPwErrMsg}
          // ref={null}
          onKeyPress={handleEnter}
        />
        <AutoLoginWrapper>
          {autoLoginEnabled && (
            <StyledCheckBoxChecked onClick={() => setAutoLoginEnabled(false)} />
          )}
          {!autoLoginEnabled && (
            <StyledCheckBoxUnChecked
              onClick={() => setAutoLoginEnabled(true)}
            />
          )}
          <AutoLoginText>자동로그인</AutoLoginText>
        </AutoLoginWrapper>
        <StyledFormButton
          buttonText="로그인"
          type={isButtonEnabled}
          onClick={onFormHandler}
        />
      </Form>
    </Wrapper>
  );
};

export default LoginFormPassword;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AutoLoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

const AutoLoginText = styled.p`
  color: #4a5561;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
`;

const StyledFormButton = styled(FormButton)`
  margin-top: 50px;
  margin-bottom: 48px;
`;
