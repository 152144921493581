import React from "react";
import { Routes, Route } from "react-router-dom";
import ErrorTemporary from "page/error/temporary";
import ErrorNotFound from "page/error/notFound";
import Login from "page/login";
import SignUp from "page/signUp";
import Pos from "page/pos";
import AuthLayout from "layout/AuthLayout";
import List from "page/orderList";
import { ProtectedRoute } from "./auth/ProtectedRoute";

const Routers = () => {
  return (
    <>
      <Routes>
        {/* USER */}
        <Route path="/" element={<AuthLayout title={"VOGO 포스 로그인"} />}>
          <Route path="" element={<Login />} />
        </Route>
        {/* <Route path="/" element={<AuthLayout title={"VOGO 포스 로그인"} />}>
          <Route path="login" element={<Login />} />
        </Route> */}
        <Route path="/" element={<AuthLayout title={"VOGO 포스 회원가입"} />}>
          <Route path="signUp" element={<SignUp />} />
        </Route>
        <Route
          path="/orderList"
          element={
            <ProtectedRoute>
              <List />
            </ProtectedRoute>
          }
        />
        {/*<Route
          path="/:code"
          element={
            <ProtectedRoute>
              <Pos />
            </ProtectedRoute>
          }
        />*/}
        {/* ERROR */}
        <Route path="/error" exact element={<ErrorTemporary />} />
        <Route path="/error/notfound" element={<ErrorNotFound />} />
      </Routes>
    </>
  );
};

export default Routers;
