import { PRETENDARD } from "components/shared/common/fontFamily";
import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ToastIcon } from "assets/images/pos/ic_vogo_pos_toast.svg";

type IProps = {
  text?: string;
  isOpen?: boolean;
  onClose?: () => void;
  toast: boolean;
  setToast?: any;
  setToastText?: any;
  onToastComplete?: () => void; // 토스트 메시지가 완전히 처리된 후에 실행될 함수
};

const Toast = memo(
  ({ text, toast, setToast, onToastComplete = () => {} }: IProps) => {
    useEffect(() => {
      let timer: number;

      if (toast) {
        onToastComplete(); // 여기서 onToastComplete는 토스트가 종료된 후에 실행될 함수
        timer = window.setTimeout(() => {
          setToast(false);
        }, 3000);
      }

      // 컴포넌트가 언마운트되거나 종속성이 변경될 때 실행
      return () => {
        if (timer) {
          clearTimeout(timer);
          localStorage.removeItem("orderSuccess");
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toast]);

    return (
      <>
        {toast && (
          <Wrapper>
            <ToastIcon />
            {text}
          </Wrapper>
        )}
      </>
    );
  }
);

export default Toast;

const Wrapper = styled.div`
  display: flex;
  width: 34.3rem;
  padding: 1.4rem 1.6rem;
  align-items: center;
  gap: 0.6rem;
  border-radius: 1rem;
  background: var(--Achromatic-ToastBlack, rgba(0, 0, 0, 0.8));
  color: white;

  position: fixed;
  top: 90%;
  left: 50%;
  z-index: 1100;
  transform: translate(-50%, -50%);
  z-index: 1000;

  color: var(--Achromatic-DefaultWhite, #fff);

  /* Body/Body_16_B */
  font-family: ${PRETENDARD};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 150% */
  letter-spacing: -0.2px;
`;
