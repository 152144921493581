import React, { useState } from "react";
import styled, { css } from "styled-components";

export type FilterButtonProps = {
  onClick: (e: React.MouseEvent) => void;
  buttonText: string;
  value: number;
  filterTypeNumber: number;
  width?: string;
  height?: string;
};
export type FilterButtonState = "unChecked" | "checked";

const FilterButton = ({
  buttonText,
  onClick,
  value,
  filterTypeNumber,
}: FilterButtonProps) => {
  //
  const determineFilterButtonState = (
    value: number,
    filterTypeNumber: number
  ) => {
    if (filterTypeNumber === value) {
      return "checked";
    } else {
      return "unChecked";
    }
  };

  return (
    <Button
      $buttonstate={determineFilterButtonState(filterTypeNumber, value)}
      onClick={onClick}
      value={value}
    >
      {buttonText}
    </Button>
  );
};

export default FilterButton;

const Button = styled.button<{
  $buttonstate: FilterButtonState;
}>`
  display: inline-flex;
  height: 3.6rem;
  padding: 0px 1rem;
  align-items: center;
  gap: 1.6rem;
  flex-shrink: 0;
  border-radius: 1rem;
  cursor: pointer;
  ${({ $buttonstate }) => $buttonstate && FilterButtonTypeStyle($buttonstate)}
`;

const FilterButtonTypeStyle = (type: FilterButtonState) => {
  let Background = "";
  let Color = "";
  switch (type) {
    case "checked":
      Background = "#343a40";
      Color = "#FFFFFF";
      break;
    case "unChecked":
      Background = "#F1F3F5";
      Color = "#4A5561";
      break;
    default:
      Background = "#F1F3F5";
      Color = "#4A5561";
      break;
  }
  return css`
    background: ${Background};
    color: ${Color};
  `;
};
