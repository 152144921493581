import { fontSizePx, fontSizeRem } from "components/shared/common/newFontSize";
import fontFamily from "components/shared/common/newFontFamily";
import breakpoint from "components/shared/common/breakpoint";

const theme = {
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    gray100: "#F0F0F0",
    gray200: "#C8C8C8",
    grayInputTxt: "#9A9A9A",
    grayInfoTxt: "#808080",
    green: "#00C4B3",
  },
  fontFamily,
  fontSizePx,
  fontSizeRem,
  breakpoint,
  mediaQuery: {
    mobile: `screen and (min-width: ${breakpoint.mobile})`,
    tablet: `screen and (min-width: ${breakpoint.tablet})`,
    desktop: `screen and (min-width: ${breakpoint.desktop})`,
  },
};

export default theme;
