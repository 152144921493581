export const checkAgent = () => {
  let mobile = /iphone|ipad|ipod|android/i.test(
    navigator.userAgent.toLowerCase()
  );
  let currentOS;
  let userAgent = navigator.userAgent.toLowerCase();
  if (mobile) {
    if (userAgent.search("android") > -1) currentOS = "android";
    else if (
      userAgent.search("iphone") > -1 ||
      userAgent.search("ipod") > -1 ||
      userAgent.search("ipad") > -1
    )
      currentOS = "ios";
  } else {
    if (userAgent.search("safari") > -1) currentOS = "safari";
    else if (userAgent.search("chrome") > -1) currentOS = "chrome";
    else currentOS = "nomobile";
  }
  return currentOS;
};
