import React from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { ReactComponent as VogoTitle } from "assets/images/pos/ic_vogo_pos_auth.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 9rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.1rem;
  margin-bottom: 6.8rem;
`;

interface AuthLayoutProps {
  title: string;
}

const AuthLayout = ({ title }: AuthLayoutProps) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <VogoTitle />
      </TitleWrapper>
      <Outlet />
    </Wrapper>
  );
};

export default AuthLayout;
