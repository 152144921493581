import {posApi} from "../services/posApi";

function toNumberArray(buffer: ArrayBuffer | null): number[] {
  if (buffer == null) return [];
  const uint8Array = new Uint8Array(buffer);
  return Array.from(uint8Array);
}

function conv(buffer: ArrayBuffer | null) {
  return btoa(String.fromCharCode.apply(null, toNumberArray(buffer)));
}

async function getRegistration() {
  return await navigator.serviceWorker.ready;
}

function subscribe(registration: ServiceWorkerRegistration) {
  const applicationServerKey = 'BBpqxE1Z2ZoVVBr4YwhLmFfkNnVEWLnCRY2s6NEgpaYy_B1kkwQVHILxD5VpaNdEp1xPWyqg9lFxFrTybDokEJs';
  const option = {
    userVisibleOnly: true,
    applicationServerKey: applicationServerKey,
  }
  registration.pushManager.subscribe(option)
    .then(postSubscribe)
    .catch(error => {
      console.error('Subscription failed:', error);
      alert('이 사이트에 대한 알림 사용 권한을 허용해야 결제시 알림을 받을 수 있습니다.');
    })
}

function postSubscribe(subscription: PushSubscription) {
  posApi.subscribe({
    tagId: localStorage.getItem('tagId'),
    endpoint: subscription.endpoint,
    keys: {
      p256dh: conv(subscription.getKey('p256dh')),
      auth: conv(subscription.getKey('auth')),
    }
  }).then(() => {
    console.log('subscription registered');
  })
}
function isNotificationGranted() {
  const msg = '푸시 알림 권한 요청을 허용하지 않으면 서비스 이용에 불편이 발생할 수 있습니다.';
  switch (Notification.permission) {
    case 'granted':
      return true;
    case 'denied':
      alert(msg);
      break;
    default:
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          sw.subscribe();
        } else {
          alert(msg);
        }
      });
  }
  return false;
}
export const sw = {
  subscribe: () => {
    if (!isNotificationGranted()) {
      return;
    }

    getRegistration().then((registration) => {
      console.log(registration);
      registration.pushManager.getSubscription()
        .then(subscription => {
          if (subscription) {
            subscription.unsubscribe().then(() => {
              subscribe(registration);
            });
          } else {
            subscribe(registration);
          }
        })
    })
  },
  postMessage: (messagePort: MessagePort) => {
    navigator.serviceWorker.ready.then((registration) => {
      if (registration) {
        registration.active?.postMessage(`Hi, worker. i'm channel`, [messagePort]);
      }
    })
  }
}