// 예시 : 홍*동
export const replaceMiddleWithAsterisk = (name: string) => {
  return name.replace(/(?<=^.{1}).(?=.)/, "*");
};

type EmailMsgType = "invalid" | "valid" | "required";

// 이메일 정규식
export const onEmailValidationHandler = (id: string): EmailMsgType => {
  if (!id) {
    return "required";
  } else if (!/\S+@\S+\.\S+/.test(id)) {
    return "invalid";
  }
  return "valid";
};

// 휴대폰 정규식
export const validatePhoneNumber = (phoneNumber: any) => {
  // 정규 표현식을 사용한 유효성 검사
  const regex = /^01[0-9]-?\d{3,4}-?\d{4}$/;
  return regex.test(phoneNumber);
};
