import axios from "axios";
import {reissueError} from "utils/errorFunction";
import {isTokenExpiringSoon, postJwtReissue} from "utils/token";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/pos`,
  headers: {
    os: process.env.REACT_APP_OS,
    version: process.env.REACT_APP_VERSION,
  },
});

// // 요청 인터셉터 추가 - 변경
// api.interceptors.request.use(
//   async (config) => {
//     const accessToken = localStorage.getItem("accessToken"); // 현재 액세스 토큰
//     const refreshToken = localStorage.getItem("refreshToken"); // 현재 액세스 토큰
//     // refreshToken이 만료되기 10초 전 이라면 바로 로그인 페이지로 이동
//     if (isTokenExpiringSoon(refreshToken)) {
//       window.location.href = process.env.REACT_APP_LOGIN_URL;
//       return;
//     }

//     console.log("요청 인터셉터");

//     // aceessToken이 토큰이 만료되기 10초 전이면
//     if (isTokenExpiringSoon(accessToken)) {
//       try {
//         const newTokens = await postJwtReissue(accessToken, refreshToken); // 토큰 재발급 함수 호출
//         // 새롭게 발급 받은 토큰을 헤더에 저장
//         config.headers["Authorization"] = `Bearer ${newTokens.accessToken}`;
//       } catch (error) {
//         // 토큰 재발급 실패 처리
//         console.error("Failed to reissue token:", error);
//       }
//     } else {
//       config.headers["Authorization"] = `Bearer ${accessToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // 응답 인터셉터
// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const {
//       config,
//       response: { status },
//     } = error;

//     const originalRequest = config;

//     console.log(error);
//     console.log("응답 인터셉터");
//     // 토큰 만료 오류 확인
//     if (error?.response.status === 401) {
//       try {
//         const newTokens = await postJwtReissue(accessToken, refreshToken);
//         api.defaults.headers.common.Authorization = `Bearer ${newTokens.accessToken}`;

//         // 중단된 요청을 토큰을 갱신한 뒤에 다시 원래 요청하려던 api 요청 재시도
//         return api(originalRequest);
//       } catch (refreshError) {
//         window.location.href = process.env.REACT_APP_LOGIN_URL;
//         if (refreshError?.response.status === 401) {
//           // 리프레시 토큰도 만료된 경우 로그인 페이지로 리다이렉트
//           window.location.href = process.env.REACT_APP_LOGIN_URL;
//         }
//       }
//     }

//     return Promise.reject(error);
//   }
// );

api.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (isTokenExpiringSoon(refreshToken)) {
      reissueError(accessToken, refreshToken);
      return;
    }

    if (isTokenExpiringSoon(accessToken) && !config.url.includes("/reissue")) {
      // 토큰 재발급 요청이 아닌 경우에만 재발급 호출
      try {
        const newTokens = await postJwtReissue(accessToken, refreshToken);
        config.headers["Authorization"] = `Bearer ${newTokens.accessToken}`;
      } catch (error) {
        console.error("Failed to reissue token:", error);
        reissueError(accessToken, refreshToken);
        return;
      }
    } else {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => error
);

api.interceptors.response.use(response => response,
  async (error) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const originalRequest = error.config;

    if (
      error?.response?.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.includes("/reissue")
    ) {
      originalRequest._retry = true;
      try {
        const newTokens = await postJwtReissue(accessToken, refreshToken);
        api.defaults.headers.common.Authorization = `Bearer ${newTokens.accessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Token reissue failed:", refreshError);
        reissueError(accessToken, refreshToken);
        return;
      }
    }

    return Promise.reject(error);
  }
);

export const posApi = {
  postAuthNumber: (mobile) =>
    api.post("/auth", {
      mobile,
    }),
  getHotplCode: (code) => api.get(`/${code}`),
  postUseHotplCode: (code, price, token) =>
    api.post(
      ``,
      {code, price},
      {
        headers: {Authorization: `Bearer ${token}`},
      }
    ),

  subscribe: (data) => api.post('/push/subscribe', data),
  retrieveCodeByTagId: () => api.get(`/code/retrieve/${localStorage.getItem("tagId")}`)
    .then(res => res.data)
    .catch(() => '')
};
