import { jwtApi } from "services/jwtTokenApi";

// 토큰의 유효성을 검사하는 함수
export const isTokenUTCTimeExpired = (token) => {
  if (!token) {
    return true;
  }
  // 여기에 토큰 만료 여부를 확인하는 로직을 구현
  // JWT 토큰의 만료 시간을 파싱하고 현재 시간과 비교
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const exp = payload.exp;
    const now = Math.floor(Date.now() / 1000); // 현재 시간을 유닉스 타임스탬프로 변환

    return now >= exp; // 현재 시간이 만료 시간보다 크거나 같으면 만료된 것으로 간주
  } catch (error) {
    console.error("Error decoding token:", error);
    return true; // 디코딩 중 오류가 발생하면 토큰을 만료된 것으로 간주
  }
};

//
// refreshToken
// 토큰 리이슈 처리
// export const postJwtReissue = async () => {
//   const refreshToken = localStorage.getItem("refreshToken");
//   const accessToken = localStorage.getItem("accessToken");
//   // refreshToken이 만료되면 로그인 페이지로 이동하기
//   if (isTokenExpiringSoon(refreshToken)) {
//     window.location.href = process.env.REACT_APP_LOGIN_URL;
//     return;
//   }
//   try {
//     const response = await posApi.postReissue(accessToken, refreshToken);
//     const { meta, data } = response.data;

//     if (meta.code === 0 && data.accessToken && data.refreshToken) {
//       localStorage.setItem("accessToken", data.accessToken);
//       localStorage.setItem("refreshToken", data.refreshToken);

//       // 새로운 토큰 반환
//       return { accessToken: data.accessToken, refreshToken: data.refreshToken };
//     } else {
//       // throw new Error("Token reissue error");
//       // 리프레시 토큰도 만료된 경우 로그인 페이지로 리다이렉트
//       window.location.href = process.env.REACT_APP_LOGIN_URL;
//     }
//   } catch (error) {
//     window.location.href = process.env.REACT_APP_LOGIN_URL;
//     if (error?.response.status === 401) {
//       // 리프레시 토큰도 만료된 경우 로그인 페이지로 리다이렉트
//       window.location.href = process.env.REACT_APP_LOGIN_URL;
//     }
//     console.error("Token reissue error:", error);
//     throw error;
//   }
// };

export const postJwtReissue = async (accessToken, refreshToken) => {
  if (isTokenExpiringSoon(refreshToken)) {
    throw new Error("Refresh token expired");
  }

  try {
    const response = await jwtApi.postReissue(accessToken, refreshToken);
    const { meta, data } = response.data;

    if (meta.code === 0 && data.accessToken && data.refreshToken) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      return { accessToken: data.accessToken, refreshToken: data.refreshToken };
    } else {
      throw new Error("Token reissue failed");
    }
  } catch (error) {
    console.error("Token reissue error:", error);
    throw error; // 에러를 던짐
  }
};

export const isTokenKSTTimeExpired = (token) => {
  if (!token) {
    return true;
  }

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const exp = payload.exp;
    const now = new Date(); // 현재 시간을 Date 객체로 생성
    const kstNow = new Date(now.getTime() + 9 * 60 * 60 * 1000); // KST로 변환 (UTC + 9시간)
    const expDate = new Date(exp * 1000); // exp를 Date 객체로 변환 (밀리초 단위로 변환 필요)

    return kstNow >= expDate; // KST 현재 시간이 만료 시간보다 크거나 같으면 만료된 것으로 간주
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

// JWT 토큰 해독 및 유효성 검사 함수
export const isTokenExpiringSoon = (token) => {
  if (!token) {
    return true; // 토큰이 없는 경우 만료됨으로 처리
  }

  try {
    const base64Url = token.split(".")[1]; // 페이로드 부분 추출
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    // window.atob Base64로 인코딩된 페이로드를 디코딩한뒤 json 형태로 파싱
    const payload = JSON.parse(window.atob(base64));

    const now = Date.now().valueOf() / 1000; // 현재 시간 (초 단위)
    const expirationTime = payload.exp;
    const timeBeforeExpiration = expirationTime - now; // 만료까지 남은 시간
    // 토큰이 만료되기 10초 전인지 확인
    if (timeBeforeExpiration < 10) {
      return true; // 만료되기 10초 전이거나 이미 만료된 경우
    }

    return false; // 토큰이 여전히 유효한 경우
  } catch (error) {
    // 유효하지 않은 토큰 형식 등의 오류 처리
    return true;
  }
};
