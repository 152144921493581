import React from "react";
import styled from "styled-components";
import SignUpForm from "components/signUp/signUpForm";

type Props = {};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const SignUp = ({}: Props) => {
  return (
    <Wrapper>
      <SignUpForm/>
    </Wrapper>
  );
};

export default SignUp;
