import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "assets/images/pos/ic_signup_modal_check_icon.svg";

type Props = {
  isOpen?: boolean;
};

const SignUpModal = ({ isOpen }: Props) => {
  return (
    <Wrapper>
      <BackDim>
        <Modal>
          <CheckIcon />
        </Modal>
      </BackDim>
    </Wrapper>
  );
};

export default SignUpModal;

const Dim = styled.div`
  position: flxed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const BackDim = styled.div<{
  isOpen?: boolean;
}>`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1100;
  transform: translate(-50%, -50%);
  width: 93%;
  max-width: 504px;
  border-radius: 20px;
  background-color: white;
`;

const Wrapper = styled.div`
  width: 100%;
`;
