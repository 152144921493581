import React from "react";
import styled from "styled-components";
import { PRETENDARD } from "components/shared/common/fontFamily";
import Button from "components/common/Button";

type SModalProps = {
  enableButtonText: string;
  titleText?: string;
  subText?: string;
  textGap?: string;
  showModal: boolean;
  setShowModal: React.Dispatch<boolean>;
  onClickHandler?: any;
};
type SModalStyleProps = { width: string; height: string };

const Modal = ({
  titleText,
  subText,
  textGap,
  enableButtonText,
  showModal,
  setShowModal,
  onClickHandler,
  width,
  height,
}: SModalProps & SModalStyleProps) => {
  return (
    <Wrapper>
      {showModal && (
        <>
          <Dim>
            <SModal width={width} height={height}>
              <ModalWrapper>
                <TextWrapper textGap={textGap}>
                  <Title>{titleText}</Title>
                  {subText && (
                    <SubText
                      dangerouslySetInnerHTML={{ __html: subText }}
                    ></SubText>
                  )}
                </TextWrapper>
                <ButtonWrapper>
                  <CloseButton onClick={() => setShowModal(false)}>
                    닫기
                  </CloseButton>
                  <Button
                    buttonText={enableButtonText}
                    buttontextsize={"1.5rem"}
                    onClick={onClickHandler}
                    type="enabled"
                    width="50%"
                    height="6.1rem"
                  />
                </ButtonWrapper>
              </ModalWrapper>
            </SModal>
          </Dim>
        </>
      )}
    </Wrapper>
  );
};

export default Modal;

const Wrapper = styled.div``;

const Dim = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
`;

const SModal = styled.div<SModalStyleProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: white;
  padding: 4rem 2rem 2rem;
  border-radius: 2rem;
`;

const TextWrapper = styled.div<{ textGap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ textGap }) => textGap};
`;

const Title = styled.h1`
  color: #343a40;

  /* Headline/Headline_20_B */
  font-family: ${PRETENDARD};
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 140% 28px*/
  letter-spacing: -0.5px;
`;

const SubText = styled.h5`
  color: #4a5561;
  text-align: center;

  /* Body/Body_15_R */
  font-family: ${PRETENDARD};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 160% 24px*/
  letter-spacing: -0.4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const CloseButton = styled.button<{
  buttonTextSize?: string;
}>`
  display: flex;
  width: 50%;
  height: 6.1rem;
  padding: 1.6rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;

  border-radius: 1rem;
  background: #f1f3f5;

  color: #4a5561;
  text-align: center;

  /* Body/Body_14_R */
  font-family: ${PRETENDARD};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%; /* 20px */
  letter-spacing: -0.2px;
  font-size: ${({ buttonTextSize }) =>
    buttonTextSize ? buttonTextSize : "15px"};
`;

export const RefundButton = styled.button`
  display: flex;
  width: 50%;
  height: 6.1rem;
  padding: 1.6rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;

  color: #4a5561;
  text-align: center;

  /* Body/Body_16_R */
  font-family: ${PRETENDARD};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 150% */
  letter-spacing: -0.2px;
  border-radius: 10px;
  background: #f1f3f5;
`;
