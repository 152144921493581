import { ERROR_MSG } from "./errMessages";

// errorCodes.js
export const ERROR = {
  // 핫플 패스 정보 조회
  HOTPLACE_PASS_INFO_QUERY: {
    // 해당 지점에서 사용가능한 핫플패스가 아닙니다. 3333333333333333
    PRODUCT_UNAVAILABLE: {
      CODE: 400100,
      MESSAGE: ERROR_MSG.HOTPLACE_PASS_INFO_QUERY.PRODUCT_UNAVAILABLE,
      PLACEHOLDER: ERROR_MSG.HOTPLACE_PASS_INFO_QUERY.INVALID_GIFT_CARD,
    },
    // 존재하지 않는 핫플패스 코드입니다. 1234567812345678
    INVALID_PRODUCT: {
      CODE: 400010,
      MESSAGE: ERROR_MSG.HOTPLACE_PASS_INFO_QUERY.INVALID_PRODUCT_CODE,
      PLACEHOLDER: ERROR_MSG.HOTPLACE_PASS_INFO_QUERY.INVALID_GIFT_CARD,
    },
    // 사용할 수 없는 핫플패스입니다. 1234567812345678
    INVALID_HOTPLACE_PASS: {
      CODE: 400020,
      MESSAGE: ERROR_MSG.HOTPLACE_PASS_INFO_QUERY.INVALID_PRODUCT_CODE,
      PLACEHOLDER: ERROR_MSG.HOTPLACE_PASS_INFO_QUERY.INVALID_GIFT_CARD,
    },
    // 잔액이 모두 소진된 핫플패스입니다. 1111111111111111
    NO_BALANCE: {
      CODE: 400030,
      MESSAGE: "",
      PLACEHOLDER: ERROR_MSG.HOTPLACE_PASS_INFO_QUERY.NO_BALANCE,
    },
  },
  // 핫플패스 예치금 사용
  USE_HOTPLACE_PASS_BALANCE: {
    // 잔액이 모두 소진된 핫플패스입니다.
    NO_BALANCE: {
      CODE: 400030,
      MESSAGE: "",
      PLACEHOLDER: ERROR_MSG.USE_HOTPLACE_PASS_BALANCE.NO_BALANCE,
    },
    // 잔액이 부족합니다. 잔액 : null
    NO_BALANCE2: {
      CODE: 400040,
      MESSAGE: "",
      PLACEHOLDER: ERROR_MSG.USE_HOTPLACE_PASS_BALANCE.NO_BALANCE,
    },
    NO_ORDERING_HOTPLACE_PASS: {
      CODE: 400150,
      MESSAGE: "",
      PLACEHOLDER: ERROR_MSG.USE_HOTPLACE_PASS_BALANCE.NO_BALANCE,
    },
  },
  // 지점 로그인 요청
  STORE_BRANCH_SIGNIN: {
    // ID는 6자 이상이어야 합니다.
    ID_LENGTH_MIN: {
      CODE: 400050,
      MESSAGE: ERROR_MSG.STORE_BRANCH_SIGNIN.ID_LENGTH_MIN,
    },
    // ID는 20자를 초과할 수 없습니다.
    ID_LENGTH_MAX: {
      CODE: 400051,
      MESSAGE: ERROR_MSG.STORE_BRANCH_SIGNIN.ID_LENGTH_MAX,
    },
    // 비밀번호는 8자 이상이어야 합니다.
    PW_LENGTH_MIN: {
      CODE: 400060,
      MESSAGE: ERROR_MSG.STORE_BRANCH_SIGNIN.PW_LENGTH_MIN,
    },
    PW_LENGTH_MAX: {
      CODE: 400061,
      MESSAGE: ERROR_MSG.STORE_BRANCH_SIGNIN.PW_LENGTH_MAX,
    },
    NO_STORE_BRANCH: {
      CODE: 400080,
      MESSAGE: ERROR_MSG.STORE_BRANCH_SIGNIN.NO_STORE_BRANCH,
    },
    NO_MATCH_PW: {
      CODE: 400090,
      MESSAGE: ERROR_MSG.STORE_BRANCH_SIGNIN.NO_MATCH_PW,
    },
  },
};
