import React from "react";
import styled from "styled-components";

type Props = {
  label: string;
};

const InputLabel = ({ label }: Props) => {
  return <SLabel>{label}</SLabel>;
};

export default InputLabel;

const Wrapper = styled.div``;

export const SLabel = styled.label`
  color: #252525;
  font-family: ${({ theme }) => theme.fontFamily.PRETENDARD};
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.2px;
`;
