export const fontSizePx = {
  SPRIMARY: "14px",
  XXSMALL: "11px",
  XSMALL: "12px",
  SMALL: "13px",
  MEDIUM: "15px",
  XMEDIUM: "18px",
  LARGE: "20px",
};

export const fontSizeRem = {
  SPRIMARY: "1.4rem",
  XXSMALL: "1.1rem",
  XSMALL: "1.2rem",
  SMALL: "1.3rem",
  MEDIUM: "1.5rem",
  XMEDIUM: "1.8rem",
  LARGE: "2rem",
};
