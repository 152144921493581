import { PRETENDARD } from "components/shared/common/fontFamily";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

type Props = {
  timeSeconds: number;
  isTimerStart: boolean;
  setIsTimerStart: any;
  setShowCodeTimer: any;
  initialCode?: string; // initialCode 추가
};

const GetCodeTimer = ({
  timeSeconds,
  isTimerStart,
  setIsTimerStart,
  setShowCodeTimer,
  initialCode,
}: Props) => {
  const [timerSeconds, setTimerSeconds] = useState(timeSeconds * 1000);
  const [timeLeft, setTimeLeft] = useState<number>(timerSeconds);
  const interval = 1000;
  const second = String(Math.floor((timeLeft / 1000) % 61)).padStart(2, "0");

  useEffect(() => {
    setTimerSeconds(timeSeconds * 1000);
    setTimeLeft(timerSeconds);
  }, [initialCode]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - interval);
    }, interval);

    // if (isTimerStart) {
    // 타이머 종료
    if (timeLeft <= 1) {
      setIsTimerStart(false);
      clearInterval(timer);
      // setTimerSeconds(timeSeconds * 1000);

      //   setIsTimerStart(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  //   useEffect(() => {
  //     if (isTimerStart) {
  //       const timer = setInterval(() => {
  //         setTimeLeft((prevTime) => prevTime - interval);
  //       }, interval);

  //       // if (isTimerStart) {
  //       // 타이머 종료
  //       if (timeLeft <= 1) {
  //         setIsFinished(true);
  //         setIsTimerStart(false);
  //         clearInterval(timer);
  //         // setTimerSeconds(timeSeconds * 1000);

  //         //   setIsTimerStart(false);
  //       }

  //       return () => {
  //         clearInterval(timer);
  //       };
  //     }
  //   }, [timeLeft, isTimerStart]);

  return (
    <Wrapper>
      {isTimerStart ? (
        <>
          <TimerTimeText>{second}</TimerTimeText>
          <TimerText>초 이내로 결제를 완료해주세요</TimerText>
        </>
      ) : (
        <TimerText>상품코드를 다시 조회해주세요</TimerText>
      )}
    </Wrapper>
  );
};

export default GetCodeTimer;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TimerText = styled.h5`
  color: #4a5561;

  /* Caption/Caption_12_R */
  font-family: ${PRETENDARD};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  letter-spacing: -0.2px;
`;

const TimerTimeText = styled.p`
  color: var(--Secondary-DodgerBlue, #1c81ef);

  /* Caption/Caption_12_R */
  font-family: ${PRETENDARD};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%; /* 16px */
  letter-spacing: -0.2px;
`;
