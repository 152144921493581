import React, { useEffect, useLayoutEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import { PRETENDARD } from "components/shared/common/fontFamily";
import { ReactComponent as CalenerIcon } from "assets/images/pos/ic_calendar.svg";
import { Dim } from "./posModal";
import Button from "components/common/Button";
import { formatDate, formatDate2 } from "utils/date";

function MyDateRangePicker({
  dateRange,
  setDateRange,
  setIsClickedDateRange,
  fetchTotalPriceHandler,
  setLoadOrderList,
  setCurrentPage,
}) {
  const resetValue = formatDate(new Date());
  const [calendarRange, setCalendarRange] = useState();
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [isClickedSearchBtn, setIsClickedSearchBtn] = useState(false);
  const [isDefaultOrReset, setIsDefaultOrReset] = useState(true); // 달력에서 초기 값 여부인지 판단하는 상태값

  useLayoutEffect(() => {
    fetchTotalPriceHandler(dateRange);
  }, [dateRange]);

  const onChangeDateHandler = (value) => {
    setIsDefaultOrReset(false);
    setDateRange(value);
    sessionStorage.setItem("startDate", value[0]);
    sessionStorage.setItem("endDate", value[1]);
  };

  const onloadOrderListForRangeHandler = () => {
    // 총 합계 금액을 불러온다.

    fetchTotalPriceHandler(dateRange);
    setIsShowCalendar(false);
    setLoadOrderList(true);
  };

  const onResetDateHandler = () => {
    setDateRange([new Date(resetValue), new Date(resetValue)]);
    setIsDefaultOrReset(true); // 재설정 시 true로 설정
    setCurrentPage(0); // 현재 페이지 0으로 초기화
  };

  // dateRange가 변경될 때마다 isDefaultOrReset 상태 업데이트
  useEffect(() => {
    if (resetValue == formatDate(dateRange[0])) {
      setIsDefaultOrReset(true);
    } else {
      setIsDefaultOrReset(false);
    }
  }, [dateRange[0], resetValue]);

  return (
    <Wrapper>
      {isShowCalendar && (
        <Dim $isopen={isShowCalendar}>
          <Modal>
            <CalenderWrapper>
              <StyledCalendar
                onChange={(value) => onChangeDateHandler(value)}
                value={dateRange}
                selectRange={true}
                locale="ko-KR"
                calendarType="gregory"
                showNeighboringMonth={false} //  이전, 이후 달의 날짜는 보이지 않도록 설정
                formatDay={(locale, date) => date.getDate().toString()}
                next2Label={null}
                prev2Label={null}
                tileClassName={({ date, view }) => {
                  if (view === "month" && date.getDay() === 6) {
                    // 토요일인 경우 'sat' 클래스를 추가.
                    return "sat";
                  }
                  if (view === "month" && date.getDay() === 0) {
                    // 일요일인 경우 'sun' 클래스를 추가.
                    return "sun";
                  }
                }}
                isDefaultOrReset={isDefaultOrReset}
              />
            </CalenderWrapper>
            <FooterWrapper>
              <div>
                <ResetButton onClick={onResetDateHandler}>재설정</ResetButton>
              </div>
              <ButtonWrapper>
                <CloseButton
                  onClick={() => {
                    setIsShowCalendar(false);
                    setIsClickedDateRange(false);
                    setIsClickedSearchBtn(false);
                  }}
                >
                  닫기
                </CloseButton>
                <Button
                  buttonText="조회"
                  type="enabled"
                  width="7.2rem"
                  height="4rem"
                  onClick={onloadOrderListForRangeHandler}
                  padding="0.5rem 1rem"
                />
              </ButtonWrapper>
            </FooterWrapper>
          </Modal>
        </Dim>
      )}
      <DateWrapper onClick={() => setIsShowCalendar(true)}>
        <CalenerIcon />
        <Wrapper>
          {dateRange.length > 0 ? (
            <p className="text-center">
              {formatDate2(dateRange[0])}
              &nbsp;~&nbsp;
              {formatDate2(dateRange[1])}
            </p>
          ) : (
            <p className="text-center">{formatDate2(dateRange)}</p>
          )}
        </Wrapper>
      </DateWrapper>
    </Wrapper>
  );
}

export default MyDateRangePicker;

const Wrapper = styled.div``;

const Modal = styled.div`
  position: fixed;
  top: 33%;
  left: 18%;
  z-index: 1100;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border-radius: 1rem;
  background: white;
`;

const DateWrapper = styled.div`
  border-radius: 1rem;
  background: #f1f3f5;
  color: #4a5561;

  /* Body/Body_14_R */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%; /* 142.857% */
  letter-spacing: -0.2px;

  padding: 1rem 1rem;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 4px;
  cursor: pointer;
  white-space: nowrap;
`;

const CalenderWrapper = styled.div`
  background-color: white;
  border-radius: 1rem;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ResetButton = styled.button`
  display: flex;
  width: 7.1rem;
  padding: 1rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  white-space: nowrap;
  /* align-self: stretch; */

  color: #4a5561;

  /* Body/Body_14_R */
  font-family: ${PRETENDARD};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%; /* 20px */
  letter-spacing: -0.2px;
  text-align: center;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  gap: 0.6rem;
`;

const StyledCalendar = styled(Calendar)`
  border-radius: 1rem;
  border: none;
  height: 38.8rem;
  padding: 0.3rem 0;

  &.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }

  &.react-calendar--doubleView {
    width: 700px;
  }

  &.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }

  &.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }

  &.react-calendar,
  &.react-calendar *,
  &.react-calendar *:before,
  &.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  &.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }

  &.react-calendar button:enabled:hover {
    cursor: pointer;
  }

  &.react-calendar__navigation {
    display: flex;
    flex-direction: row;
    height: 4.4rem;
    margin-bottom: 1em;
    color: #343a40;

    /* Caption/Caption_13_B */
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 138.462%; /* 138.462% 18px*/
    letter-spacing: -0.2px;
  }

  &.react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }

  &.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }

  &.react-calendar__navigation button:enabled:hover,
  &.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  &.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
    text-decoration: none;
  }

  &.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  &.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
  }

  &.react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }

  &.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }

  &.react-calendar__year-view .react-calendar__tile,
  &.react-calendar__decade-view .react-calendar__tile,
  &.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }

  &.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
  }

  &.react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }

  &.react-calendar__tile:enabled:hover,
  &.react-calendar__tile:enabled:focus {
    /* background-color: #e6e6e6; */
    background: var(--Blurple-Blurple03, #c9beff);
    color: white;
  }

  &.react-calendar__tile--now {
    background-color: #6728ff;
    color: white;
  }

  &.react-calendar__tile--now:enabled:hover,
  &.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }

  &.react-calendar__tile--hasActive {
    background: #76baff;
  }

  &.react-calendar__tile--hasActive:enabled:hover,
  &.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }

  &.react-calendar__tile--active {
    background: var(--Blurple-Blurple03, #c9beff);
    color: white;
  }

  &.react-calendar__tile--active:enabled:hover,
  &.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }

  &.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
    background: var(--Blurple-Blurple03, #c9beff);
  }

  & .react-calendar__navigation button {
    color: #333;
    min-width: 44px;
    background: none;
    font-size: 1.6rem;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    height: 4rem;
    margin-right: 2rem;
    margin-left: 2rem;
    &.react-calendar__navigation__arrow,
    &.react-calendar__navigation__prev-button {
      width: 4rem;
      height: 4rem;
      padding: 0px 1rem;
      justify-content: center;
      align-items: center;

      border-radius: 1rem;
      background: #f1f3f5;
    }
  }

  & .react-calendar__navigation span {
    color: #343a40;

    /* Caption/Caption_13_B */
    font-family: ${PRETENDARD};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 138.462%; /* 138.462% */
    letter-spacing: -0.2px;
  }

  & .react-calendar__month-view__days__day--weekend {
    color: #e02020;
  }

  /* 토요일에 적용할 스타일 */
  .react-calendar__month-view__days__day--weekend.sat {
    color: var(--Secondary-DodgerBlue, #1c81ef);
  }
  /* 일요일에 적용할 스타일 */
  .react-calendar__month-view__days__day--weekend.sun {
    color: var(--Secondary-DodgerBlue, #e02020);
  }

  & .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 1.3rem 1rem;
    background: none;
    color: #343a40;
    text-align: center;

    /* Caption/Caption_12_R */
    font-family: ${PRETENDARD};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%; /* 133.333% */
    letter-spacing: -0.2px;
    &:enabled:hover,
    &:enabled:focus {
      /* background-color: #e6e6e6; */
      background-color: #6728ff;
      color: white;
    }
    ${({ isDefaultOrReset }) =>
      isDefaultOrReset &&
      `
    &.react-calendar__tile--now {
      background-color: #6728ff;
      color: white;
    }
  `}
    &.react-calendar__tile--rangeStart,
    &.react-calendar__tile--rangeEnd {
      background-color: #6728ff;
      color: white;
    }
  }

  & .react-calendar__tile--active {
    background: var(--Blurple-Blurple03, #c9beff);
    color: white;
    &.react-calendar__tile--rangeStart,
    &.react-calendar__tile--rangeEnd {
      background-color: #6728ff;
      color: white;
    }
  }
  &.react-calendar__tile--day::after {
    content: "";
    display: none;
  }
  .react-calendar__month-view__weekdays__weekday {
    color: #858f9c;
    text-align: center;

    /* Caption/Caption_12_R */
    font-family: ${PRETENDARD};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%; /* 133.333% */
    letter-spacing: -0.2px;
    height: 2.8rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &.react-calendar__navigation__label__labelText--from {
    color: #343a40;

    /* Caption/Caption_13_B */
    font-family: ${PRETENDARD};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 138.462%;
    letter-spacing: -0.2px;
  }
  abbr[title] {
    text-decoration: none !important;
  }
  abbr[title="일요일"] {
    color: #e02020;
  }
  abbr[title="토요일"] {
    color: #1c81ef;
  }
`;

const CloseButton = styled.button`
  display: flex;
  width: 7.1rem;
  padding: 0px 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  align-self: stretch;
  border-radius: 1rem;
  background: #f1f3f5;

  color: #4a5561;

  /* Body/Body_14_R */
  font-family: ${PRETENDARD};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%; /* 142.857%  20px*/
  letter-spacing: -0.2px;
`;

// const ResetButton = styled.
