import React from "react";
import styled, { css } from "styled-components";

export type FormButtonProps = {
  onClick: (e: React.MouseEvent) => void;
  buttonText: string;
  buttontextsize?: string;
  type: FormButtonState;
  width?: string;
  height?: string;
  $padding?: string;
};
export type FormButtonState = "enabled" | "disabled" | "pressed";

const Button = ({
  width,
  height,
  buttonText,
  buttontextsize,
  type,
  onClick,
  $padding,
}: FormButtonProps) => {
  //
  const onClickHandler = (e: any) => {
    // 버튼의 type이 enabled일 때만 click event 실행
    if (type === "enabled") {
      onClick(e);
    }
  };

  return (
    <ButtonComponent
      width={width}
      height={height}
      type={type}
      buttonTextSize={buttontextsize}
      onClick={onClickHandler}
      padding={$padding}
    >
      {buttonText}
    </ButtonComponent>
  );
};

export default Button;

const ButtonComponent = styled.button<{
  width?: string;
  height?: string;
  buttonTextSize?: string;
  type: FormButtonState;
  padding?: string;
}>`
  display: flex;
  width: ${({ width }) => (width ? width : "40rem")};
  height: ${({ height }) => (height ? height : "5.2rem")};
  padding: ${({ padding }) => (padding ? padding : "1.6rem 2rem")};
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 10px;
  font-weight: 700;
  font-size: ${({ buttonTextSize }) =>
    buttonTextSize ? buttonTextSize : "15px"};
  font-style: normal;
  line-height: 24px; /* 160% */
  letter-spacing: -0.4px;
  white-space: nowrap; /* 텍스트를 한 줄로 표시 */
  ${({ type }) => FormButtonStateStyle(type)}
`;

const FormButtonStateStyle = (type: FormButtonState) => {
  let BoxShadow = "";
  let Background = "";
  let Color = "";
  switch (type) {
    case "enabled":
      BoxShadow = "0px 6px 15px 0px rgba(103, 40, 255, 0.20)";
      Background = "#6728FF";
      Color = "#fff";
      break;
    case "disabled":
      BoxShadow = "";
      Background = "#F8F9FA";
      Color = "#CCD4D8";
      break;
    case "pressed":
      BoxShadow = "0px 3px 5px 0px rgba(103, 40, 255, 0.20)";
      Background = "#521FCF";
      Color = "#fff";
      break;
    default:
      BoxShadow = "";
      Background = "#F8F9FA";
      break;
  }
  return css`
    /* box-shadow: ${BoxShadow}; */
    background: ${Background};
    color: ${Color};
  `;
};
