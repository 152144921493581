// errorMessages.js
export const ERROR_MSG = {
  // 지점 로그인 요청
  STORE_BRANCH_SIGNIN: {
    ID_LENGTH_MIN: "ID는 6자 이상이어야 합니다.",
    ID_LENGTH_MAX: "ID는 20자를 초과할 수 없습니다.",
    PW_LENGTH_MIN: "비밀번호는 8자 이상이어야 합니다.",
    PW_LENGTH_MAX: "비밀번호는 8자 이상이어야 합니다.",
    NO_STORE_BRANCH: "존재하지 않는 지점입니다.",
    NO_MATCH_PW: "비밀번호가 일치하지 않습니다.",
  },
  // 핫플 패스 정보 조회
  HOTPLACE_PASS_INFO_QUERY: {
    PRODUCT_UNAVAILABLE: "해당지점에서 사용할 수 없는 상품입니다.",
    INVALID_PRODUCT_CODE: "상품 코드를 확인해주세요.",
    NO_BALANCE: "잔액없음",
    INVALID_GIFT_CARD: "존재하지 않는 상품권입니다.",
    UNKNOWN_ERROR: "An unknown error occurred",
  },
  // 핫플패스 예치금 사용
  USE_HOTPLACE_PASS_BALANCE: {
    NO_BALANCE: "잔액없음",
    NO_ORDERING_HOTPLACE_PASS: "현재 결제 진행중인 핫플패스가 아닙니다.",
  },
};
