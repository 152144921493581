// PhoneForm.js
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Input, InputStyleType, SLabel } from "../common/Input";
import { posApi } from "services/posApi";
import Timer from "./Timer";

type Props = {
  phoneNumber: string;
  authNumbers: string;
  // errMsg: string;
  // label: string;
  // placeholder: string;
  // inputStyleType: string; // inputStyleType을 props로 추가
};

const Wrapper = styled.div`
  width: 100%;
`;

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const AuthButton = styled.button<{ phoneNumberValid: any }>`
  display: flex;
  width: 8.6rem;
  height: 5.2rem;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  /* background: #f8f9fa;
  color: #ccd4d8; */
  background: ${({ phoneNumberValid }) =>
    phoneNumberValid === "invalid"
      ? "#dfe5e8"
      : "#6728FF"};
  color: ${({ phoneNumberValid }) =>
    phoneNumberValid === "invalid"
      ? "#acb5bd"
      : "var(--Achromatic-DefaultWhite, #FFF)"};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
`;

// export const onEmailValidationHandler = (id: string): EmailMsgType => {
//   if (!id) {
//     return "required";
//   } else if (!/\S+@\S+\.\S+/.test(id)) {
//     return "invalid";
//   }
//   return "valid";
// };

export const validatePhoneNumber = (phoneNumber: any) => {
  // 정규 표현식을 사용한 유효성 검사
  if (!phoneNumber) {
    // return "required";
  } else if (!/^01[0-9]-?\d{3,4}-?\d{4}$/.test(phoneNumber)) {
    return "invalid";
  }
  return "valid";
};

const PhoneForm = ({
  phoneNumber,
  authNumbers,
}: // phoneNumber,
// errMsg,
// label,
// placeholder,
// inputStyleType, // inputStyleType을 props로 받음
Props) => {
  const phoneRef = useRef<HTMLInputElement>(null);
  const [phone, setPhone] = useState(phoneNumber);
  const [authNumber, setAuthNumber] = useState(authNumbers);
  const [phoneErrMsg, setPhoneErrMsg] = useState("");
  const [phoneNumberValid, setPhoneNumberValid] = useState<any>("default");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [inputStyleType, setInputStyleType] =
    useState<InputStyleType>("default");
  const [isShowAuthNumberInput, setShowAuthNumberInput] = useState(false);

  useEffect(() => {
    setPhoneNumberValid("default");
  }, []);

  const handlePhoneNumberChange = (e: any) => {
    const value = e.target.value;
    setPhone(value);

    // 여기서 유효성 검사를 수행하고, 결과를 phoneNumberValid와 phoneNumberError에 저장합니다.
    let isValid = validatePhoneNumber(value); // validatePhoneNumber 함수는 유효성 검사 로직
    setPhoneNumberValid(isValid);

    if (isValid === "invalid") {
      setPhoneNumberError("");
      setInputStyleType("error");
    } else {
      setPhoneNumberError("");
      setInputStyleType("error");
    }
  };

  const handleAuthNumberChange = (e: any) => {
    const value = e.target.value;
    setAuthNumber(value);

    // 여기서 유효성 검사를 수행하고, 결과를 phoneNumberValid와 phoneNumberError에 저장합니다.
    // let isValid = validatePhoneNumber(value); // validatePhoneNumber 함수는 유효성 검사 로직
    // setPhoneNumberValid(isValid);

    // if (isValid === "invalid") {
    //   setPhoneNumberError("");
    //   setInputStyleType("error");
    // } else {
    //   setPhoneNumberError("");
    //   setInputStyleType("error");
    // }
  };

  const onClickAuthButtonHandler = (e: any, phone: string) => {
    e.preventDefault();
    if (phone !== "") {
      return;
    }
    setShowAuthNumberInput(true);
    fetchAuthNumber(phone);
  };

  const fetchAuthNumber = async (phone: string) => {
    const result = await posApi.postAuthNumber(phone);
  };

  return (
    <Wrapper>
      <SLabel>{"휴대폰 번호"}</SLabel>
      <Form onSubmit={(e) => e.preventDefault()}>
        <InputWrapper>
          <Input
            // ref={phoneRef}
            type={"text"}
            name={"phone"}
            value={phone}
            width={"30.4rem"}
            height={"52px"}
            onChange={handlePhoneNumberChange}
            placeholder={"휴대폰 번호"}
            required={"required"}
            textAlign={"center"}
            inputStyleType={"default"}
            errMsg={""}
          />
          <AuthButton
            phoneNumberValid={phoneNumberValid}
            onClick={(e) => onClickAuthButtonHandler(e, phone)}
          >
            인증하기
          </AuthButton>
        </InputWrapper>
        {isShowAuthNumberInput && (
          <InputWrappers>
            <SInput name="authNumber" type="text" value={authNumber} />
            <TimerWrapper>
              <Timer />
            </TimerWrapper>
          </InputWrappers>
        )}
      </Form>
    </Wrapper>
  );
};

export default PhoneForm;

export const SInput = styled.input<{
  $inputstate?: InputStyleType;
  type?: string;
}>`
  font-family: ${({ theme }) => theme.fontFamily.PRETENDARD};
  width: 40rem;
  height: 5.2rem;
  display: flex;
  font-size: 13px;
  padding: 0px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  line-height: 18px;
  letter-spacing: -0.2px;

  color: #4a5561;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
  border: 1px solid #acb5bd;

  /* input focus */
  &:focus {
    /* border-radius: 10px;
    outline: 1px solid #343a40;
    color: #343a40;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.2px; */
    outline: none;
  }
  &::placeholder {
    font-size: 13px;
  }
  /* 자동 완성시 채워지는 색상 변경 */
  &:-webkit-autofill &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: #fff !important;
  }
  /* 증감 버튼을 숨김 */
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="checkbox"] + label {
    display: inline-block;
    border: 1.25px solid #707070;
    position: relative;
  }
  &[type="checkbox"]:checked + label::after {
    content: "􀆅";
    /* font-size: 25px; */
    text-align: center;
    border: 1.25px solid #6728ff;
    background: #6e42ff;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const InputWrappers = styled.div`
  position: relative;
`;

export const TimerWrapper = styled.div`
  display: block;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;

  color: #6728ff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
`;
