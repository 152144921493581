import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Input, InputStyleType } from "components/common/Input";
import PhoneForm from "components/signUp/phoneForm";
import { useAuthContext } from "store/context/authContext";
import FormButton from "components/common/FormButton";

type Props = {};
type EmailMsgType = "invalid" | "valid" | "required";
// type EmailMsgType = "invalid" | "valid" | "required";

export const onEmailValidationHandler = (id: string): EmailMsgType => {
  if (!id) {
    return "required";
  } else if (!/\S+@\S+\.\S+/.test(id)) {
    return "invalid";
  }
  return "valid";
};

const LoginFormPhone = ({}: Props) => {
  const [formData, setFormData] = useState({
    id: "",
    phone: "",
  });

  const [inputStateData, setInputStateData] = useState<{
    id: InputStyleType;
    phone: InputStyleType;
  }>({
    id: "default",
    phone: "default",
  });

  const [errorMsg, setErrorMsg] = useState({
    id: "",
    phone: "",
  });

  const { login } = useAuthContext();
  const idRef = useRef<HTMLInputElement>(null);
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [emptyValue, setEmptyValue] = useState("");

  // 유효성 검증
  // const onValidationHandler = (formData: any) => {
  //   let errorMsg = {
  //     id: "",
  //     password: "",
  //   };

  //   // 이메일 검사 로직
  //   if (!formData.id) {
  //     errorMsg.id = "이메일은 필수입니다.";
  //   } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
  //     errorMsg.id = "유효한 이메일 주소가 아닙니다.";
  //   }

  //   // 비밀번호 검사 로직
  //   if (!formData.password) {
  //     errorMsg.password = "비밀번호는 필수입니다.";
  //   } else if (formData.password.length < 6) {
  //     errorMsg.password = "비밀번호는 6자 이상이어야 합니다.";
  //   }
  // };

  // input의 값을 다루는 함수
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // if (value.length > 0) {
    //   setInputStateData({ ...inputStateData, [name]: "inputting" });
    // } else {
    //   setInputStateData({ ...inputStateData, [name]: "default" });
    // }
    if (name === "id") {
      let emailType = onEmailValidationHandler(value);
      if (emailType === "valid") {
        setInputStateData({ ...inputStateData, [name]: "default" });
      } else {
        setInputStateData({ ...inputStateData, [name]: "error" });
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  // 폼 전송을 담당하는 함수
  const onFormHandler = (e: any) => {
    e.preventDefault();
    login(formData);
  };

  const handleEnter = (e: any) => {
    // if (!condition) return;
    if (e.key === "Enter") login();
  };

  useEffect(() => {
    if (idRef && idRef.current) {
      idRef.current.focus();
    }
  }, []);

  return (
    <Wrapper>
      <Form onSubmit={onFormHandler}>
        {/* id */}
        <Input
          type={"text"}
          name={"id"}
          value={formData.id}
          width={"400px"}
          height={"5.2rem"}
          onChange={onChangeHandler}
          // onFocus={onFocusHandler}
          // onBlur={onBlurHandler}
          // onKeyDown={onKeyDownHandler}
          // onKeyUp={onKeyUpHandler}
          placeholder={"아이디를 입력해주세요."}
          label={"아이디"}
          required={"required"}
          textAlign={"center"}
          readOnly={false}
          inputStyleType={inputStateData.id}
          // inputStyleType={"error"}
          errMsg={errorMsg.id}
          // ref={idRef}
        />
        {/* <Input
          type={"password"}
          name={"password"}
          value={formData.password}
          width={"400px"}
          height={"5.2rem"}
          onChange={onChangeHandler}
          placeholder={"비밀번호를 입력해주세요."}
          label={"비밀번호"}
          required={"required"}
          textAlign={"center"}
          readOnly={false}
          inputStyleType={inputStateData.password}
          errMsg={errorMsg.password}
          ref={null}
          onKeyPress={handleEnter}
        /> */}
        <PhoneForm phoneNumber={formData.phone} authNumbers={emptyValue} />
        <StyledFormButton
          buttonText="로그인"
          type={"disabled"}
          onClick={onFormHandler}
        />
      </Form>
    </Wrapper>
  );
};

export default LoginFormPhone;

const CheckBoxListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.7rem;
  gap: 1.3rem;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  /* width: 40rem; */
  gap: 2.5rem;
  padding: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckBoxLabel = styled.label`
  color: #4a5561;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
  margin-left: 10px;
`;

const Link = styled.a`
  color: #acb5bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
  text-decoration-line: underline;
`;

const AutoLoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

const AutoLoginText = styled.p`
  color: #4a5561;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
`;

const StyledFormButton = styled(FormButton)`
  margin-top: 50px;
  margin-bottom: 48px;
`;
