import axios from "axios";
import {reissueError} from "utils/errorFunction";
import {isTokenExpiringSoon} from "utils/token";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/pos`,
  headers: {
    os: process.env.REACT_APP_OS,
    version: process.env.REACT_APP_VERSION,
  },
});

api.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (isTokenExpiringSoon(refreshToken)) {
      reissueError(accessToken, refreshToken);
      return;
    }

    if (isTokenExpiringSoon(accessToken) && !config.url.includes("/reissue")) {
      // 토큰 재발급 요청이 아닌 경우에만 재발급 호출
      try {
        // const newTokens = await postJwtReissue(accessToken, refreshToken);
        // config.headers["Authorization"] = `Bearer ${newTokens.accessToken}`;
      } catch (error) {
        console.error("Failed to reissue token:", error);
        reissueError(accessToken, refreshToken);
        return;
      }
    } else {
      // config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      (error?.response?.status === 401 || error?.response?.status === 400) &&
      !originalRequest._retry &&
      !originalRequest.url.includes("/reissue")
    ) {
      originalRequest._retry = true;
    }

    return Promise.reject(error);
  }
);

export const jwtApi = {
  postReissue: (accessToken, refreshToken) =>
    api.post("/jwt/reissue", {
      accessToken,
      refreshToken,
    }),
};
