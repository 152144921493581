import React, {
  FocusEvent,
  KeyboardEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { onEmailValidationHandler } from "utils/regExp";
import { validatePhoneNumber } from "../signUp/phoneForm";
import { PRETENDARD } from "components/shared/common/fontFamily";

export type InputStyleType =
  | "default"
  | "inputting"
  | "defaultWithSubText"
  | "typing"
  | "typingDone"
  | "error"
  | "invalid";
type PasswordIconType = "on" | "off";

interface InputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  name: string;
  type?: string;
  placeholder?: string;
  required: string;
  value: string;
  width: string;
  height?: string;
  label?: string;
  pattern?: string;
  textAlign?: string;
  readOnly?: boolean;
  minLength?: number;
  maxLength?: number;
  inputStyleType: InputStyleType;
  errMsg?: string;
  // ref:
  //   | React.Ref<HTMLInputElement>
  //   | React.MutableRefObject<HTMLInputElement>
  //   | null;
  autoComplete?: string;
}

// todo : input type에 따라서 동작하게끔 설정하기
export const Input = ({onChange,
  onInput,
  onFocus,
  onBlur,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  name,
  type = "text",
  placeholder,
  required = "required",
  value,
  width = "100%",
  height,
  label,
  pattern,
  textAlign = "left",
  readOnly = false,
  minLength,
  maxLength,
  inputStyleType = "default",
  errMsg = "",
  // ref,
  autoComplete,
}: InputProps) => {
  const [passwordIconType, setPasswordIconType] =
    useState<PasswordIconType>("off");
  const [errorMsg, setErrorMsg] = useState(errMsg);
  const timer = useRef<Function>();
  const inputRef = useRef(null);

  useEffect(() => {
    determineInnerInputStyleType(errMsg);
  }, [errMsg]);

  const determineInnerInputStyleType = (errMsg: string) => {
    if (errMsg !== "") {
      setInnerInputStyleType("error");
    } else {
      setInnerInputStyleType(inputStyleType);
    }
  };

  const [innerInputStyleType, setInnerInputStyleType] =
    useState<InputStyleType>(inputStyleType);

  const onFocusHandler = (e: FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value.length > 0) {
      // setInnerInputStyleType("inputting");
      if (name === "id") {
        let emailType = onEmailValidationHandler(value);
        if (emailType === "invalid") {
          setInnerInputStyleType("error");
        }
      } else if (name === "phone") {
        let phoneType = validatePhoneNumber(value);
        if (phoneType === "invalid") {
          setInnerInputStyleType("error");
        }
      }
    } else {
      setInnerInputStyleType("default");
      // if (name === "id") {
      //   let emailType = onEmailValidationHandler(value);
      //   if (emailType === "valid") {
      //     setInnerInputStyleType("default");
      //   } else {
      //     setInnerInputStyleType("error");
      //   }
      // }
    }
  };

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value.length > 0) {
      if (name === "id") {
        let emailType = onEmailValidationHandler(value);
        if (emailType === "valid") {
          setInnerInputStyleType("default");
          setErrorMsg("");
        } else {
          setInnerInputStyleType("error");
          setErrorMsg("올바른 이메일 형식이 아닙니다.");
        }
      } else if (name === "phone") {
        let phoneType = validatePhoneNumber(value);
        if (phoneType === "valid") {
          setInnerInputStyleType("default");
          setErrorMsg("");
        } else {
          setInnerInputStyleType("error");
          setErrorMsg("올바른 휴대폰 형식이 아닙니다.");
        }
      }
    }
  };

  const onKeyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (value.length > 0) {
      setInnerInputStyleType("inputting");
      setErrorMsg("");
    }
  };

  const [isFinishedTyping, setIsFinishedTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<any>(null);

  const onKeyUpHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setInnerInputStyleType("inputting");
    setTypingTimeout(
      setTimeout(() => {
        setIsFinishedTyping(true);
        setInnerInputStyleType("default");
      }, 1000)
    ); // 1초 동안 키 입력이 없으면 타이핑이 완료된 것으로 간주
  };

  const onPasswordIconTypeToggleHandler = (
    type: string,
    name: string,
    passwordIconType: PasswordIconType
  ) => {
    if (type === "password") {
      switch (passwordIconType) {
        case "off":
          return "password";
          break;
        case "on":
          return "number";
          break;
      }
    } else {
      return type;
    }
  };

  return (
    <Wrapper>
      {label && <SLabel>{label}</SLabel>}
      <InputWrapper>
        <SInput
          name={name}
          // onInput={onInput}
          onChange={onChange}
          // onFocus={onFocusHandler}
          // onBlur={onBlurHandler}
          // onKeyDown={onKeyDownHandler}
          // onKeyUp={onKeyUpHandler}
          // onKeyPress={onKeyUpHandler}
          type={onPasswordIconTypeToggleHandler(type, name, passwordIconType)}
          placeholder={placeholder}
          value={value}
          width={width}
          height={height}
          //   required={required}
          pattern={pattern}
          readOnly={readOnly}
          minLength={minLength}
          maxLength={maxLength}
          $inputstate={innerInputStyleType}
          autoComplete={autoComplete}
          // ref={inputRef}
        />
        {}
        {errMsg !== "" && (
          <ErrorMsg $inputState={innerInputStyleType}>{errMsg}</ErrorMsg>
        )}
      </InputWrapper>
    </Wrapper>
  );
};

// const ForwardRefInput = forwardRef((props: InputProps, ref) => {
//   return <SInput {...props} ref={ref} />;
// });

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const InputStyle = (type: string, $inputstate: InputStyleType) => {
  let Border = "";
  let Color = "";
  if (type !== "checkbox") {
    switch ($inputstate) {
      case "default":
      case "defaultWithSubText":
      case "typingDone":
        Border = "1px solid #ACB5BD";
        Color = "#4A5561";
        break;
      case "error":
        Border = "1px solid #e02020";
        break;
      case "inputting":
      case "typing":
        Border = "1.5px solid #6728FF";
        break;
      default:
        Border = "1px solid #ACB5BD";
        break;
    }
  }
  return css`
    border: ${Border};
  `;
};

const InputTypeStyle = (type: string) => {
  let Border = "";
  let Background = "";
  let BorderRadius = "";
  switch (type) {
    case "checkbox":
      Border = "1.25px solid #6728FF";
      Background = "#6E42FF";
      BorderRadius = "4px";
      break;
    default:
      Border = "1.25px solid #dfe5e8";
      Background = "#fff";
      BorderRadius = "10px";
      break;
  }
  return css`
    border: ${Border};
    background: ${Background};
    border-radius: ${BorderRadius};
    &:focus {
      outline: none;
    }
  `;
};

export const SInput = styled.input<{
  $inputstate: InputStyleType;
  type: string;
}>`
  font-family: ${({ theme }) => theme.fontFamily.PRETENDARD};
  width: ${({ width }) => width};
  height: ${({ height }) => (height ? height : "auto")};
  display: flex;
  padding: 0 16px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  letter-spacing: -0.2px;

  color: #4a5561;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  /* input focus */
  &::placeholder {
    font-size: 13px;
  }
  /* 자동 완성시 채워지는 색상 변경 */
  &:-webkit-autofill &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }
  /* 증감 버튼을 숨김 */
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="checkbox"] + label {
    display: inline-block;
    border: 1.25px solid #707070;
    position: relative;
  }
  &[type="checkbox"]:checked + label::after {
    content: "􀆅";
    /* font-size: 25px; */
    text-align: center;
    border: 1.25px solid #6728ff;
    background: #6e42ff;
    position: absolute;
    left: 0;
    top: 0;
  }
  ${({ type }) => InputTypeStyle(type)};
  ${({ type, $inputstate }) => InputStyle(type, $inputstate)};
`;

const IconWrapper = styled.div``;

export const SLabel = styled.label`
  color: #252525;
  font-family: ${({ theme }) => theme.fontFamily.PRETENDARD};
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 138.462%; /* 138.462% */
  letter-spacing: -0.2px;
  text-align: left;
`;

// error message
const InputStateErrorMsgStyle = ($inputstate: InputStyleType) => {
  let Color = "";
  switch ($inputstate) {
    case "default":
      Color = "#4a5561";
      break;
    case "error":
    case "invalid":
      Color = "#E02020";
      break;
    default:
      Color = "#4a5561";
      break;
  }
  return css`
    color: ${Color};
  `;
};

const ErrorMsg = styled.p<{ $inputState: InputStyleType }>`
  ${({ $inputState }) => InputStateErrorMsgStyle($inputState)};
  font-family: ${PRETENDARD};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%; /* 133.333% */
  letter-spacing: -0.2px;
  text-align: left;
  margin-top: 0.2rem;
`;
