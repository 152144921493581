// 공백을 제거하는 함수
export const removeAllSpaces = (text: string) => {
  return text.replace(/ /gi, "");
};

export const formatPriceToNumber = (price: string | number) => {
  let resultPrice = price.toString();
  resultPrice = resultPrice.replace(" 원", "").split(",").join("");
  return Number(resultPrice);
};

export const addSpaceEveryFourChars = (text: string) => {
  return text
    .replace(/\s+/g, "")
    .replace(/(.{4})/g, "$1 ")
    .trim();
};
