import GlobalStyles from "components/shared/global/globalStyles";
import {BrowserRouter} from "react-router-dom";
import Routers from "./routes/routes";
import {AuthProvider} from "store/context/authContext";
import {QueryClient, QueryClientProvider} from "react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Routers />
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
