import React, {ChangeEvent, useEffect, useRef, useState,} from "react";
import styled from "styled-components";
import { Input, InputStyleType } from "components/common/Input";
import PhoneForm from "components/signUp/phoneForm";
import InputLabel from "./common/inputLabel";
import { useAuthContext } from "store/context/authContext";
import FormButton from "components/common/FormButton";
import { ReactComponent as CheckBoxChecked } from "assets/images/pos/ic_checkbox_input_checked.svg";
import { ReactComponent as CheckBoxUnChecked } from "assets/images/pos/ic_checkbox_input_unChecked.svg";
import SignUpModal from "./common/SignUpModal";

type Props = {};

const SignUpForm = ({}: Props) => {
  const [formData, setFormData] = useState({
    id: "",
    phone: "",
    authNumber: "",
    condition1: false,
    condition2: false,
    condition3: false,
  });
  // const [formData, setFormData] = useState({
  //   ownerIdx: 1,
  //   id: "",
  //   password: "",
  //   store: "서초구 별 2호점",
  // });
  const [errorMsg, setErrorMsg] = useState({
    id: "",
    phone: "",
    password: "",
  });

  const [inputStateData, setInputStateData] = useState<{
    id: InputStyleType;
    password: InputStyleType;
    condition1: boolean;
    condition2: boolean;
    condition3: boolean;
  }>({
    id: "default",
    password: "default",
    condition1: false,
    condition2: false,
    condition3: false,
  });

  const idRef = useRef<HTMLInputElement>(null);
  const { signUp } = useAuthContext();
  const [formButtonValid, setFormButtonValid] = useState(false);
  const [isModal, setIsModal] = useState(false);

  // input의 값을 다루는 함수
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // if (value.length > 0) {
    //   setInputStateData({ ...inputStateData, [name]: "inputting" });
    // } else {
    //   setInputStateData({ ...inputStateData, [name]: "default" });
    // }
    if (name === "id") {
      // let emailType = onEmailValidationHandler(value);
      let emailType = "valid";
      if (emailType === "valid") {
        setInputStateData({ ...inputStateData, [name]: "default" });
      } else {
        setInputStateData({ ...inputStateData, [name]: "error" });
      }
    }
    setFormData({ ...formData, [name]: value });
    // setTestFormData({ ...testFormData, [name]: value });
  };

  // const signUp = async (formData: any) => {
  //   try {
  //     const result = await storeApi.postSignUp(formData);
  //     const { meta, data } = result.data;
  //     if (meta.code === 0) {
  //       if (data.jwtToken) {
  //         // setAccessToken(data.jwtToken.accessToken);
  //         // setRefreshToken(data.jwtToken.refreshToken);
  //         localStorage.setItem("accessToken", data.jwtToken.accessToken);
  //         localStorage.setItem("refreshToken", data.jwtToken.refreshToken);
  //       }
  //     } else {
  //       alert(meta.message);
  //     }
  //   } catch (error) {
  //     // 오류 처리
  //   }
  // };

  // 폼 전송을 담당하는 함수
  const onFormHandler = (e: any) => {
    e.preventDefault();
    signUp(formData);
  };

  const handleEnter = (e: any) => {
    // if (!condition) return;
    if (e.key === "Enter") {
      signUp(formData);
    }
  };

  useEffect(() => {
    if (idRef && idRef.current) {
      idRef.current.focus();
    }
  }, []);

  const onClickHandler = () => {
    setIsModal(true);
  };

  return (
    <Wrapper>
      {/* <button onClick={onClickHandler}>확인</button> */}
      <Form onSubmit={(e) => e.preventDefault()}>
        <InputWrapper>
          <Input
            type={"text"}
            name={"id1"}
            value={formData.id}
            width={"40rem"}
            height={"5.2rem"}
            onChange={onChangeHandler}
            // onFocus={onFocusHandler}
            // onBlur={onBlurHandler}
            // onKeyDown={onKeyDownHandler}
            // onKeyUp={onKeyUpHandler}
            placeholder={"아이디를 입력해주세요."}
            label={"아이디"}
            required={"required"}
            textAlign={"center"}
            readOnly={false}
            inputStyleType={inputStateData.id}
            // inputStyleType={"error"}
            errMsg={errorMsg.id}
            // ref={idRef}
          />
          {/* <Input
            type={"password"}
            name={"password"}
            value={formData.password}
            width={"40rem"}
            height={"5.2rem"}
            onChange={onChangeHandler}
            placeholder={"비밀번호를 입력해주세요."}
            label={"비밀번호"}
            required={"false"}
            textAlign={"center"}
            readOnly={false}
            inputStyleType={inputStateData.password}
            errMsg={errorMsg.password}
            ref={null}
            onKeyPress={handleEnter}
          /> */}
          <PhoneForm
            phoneNumber={formData.phone}
            authNumbers={formData.authNumber}
          />
        </InputWrapper>

        {/* 서비스 이용 방침 */}
        <Wrapper>
          <InputLabel label={"서비스 이용방침"} />
          <CheckBoxListWrapper>
            <CheckBoxLabelWrapper>
              {!formData.condition1 && (
                <StyledCheckBoxUnChecked
                  onClick={() => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      condition1: true,
                      condition2: true,
                      condition3: true,
                    }));
                  }}
                />
              )}
              {formData.condition1 && (
                <StyledCheckBoxChecked
                  onClick={() => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      condition1: false,
                      condition2: false,
                      condition3: false,
                    }));
                  }}
                />
              )}
              {/* <Input
                type={"checkbox"}
                name={"condition1"}
                value={formData.condition1}
                width={"2rem"}
                height={"2rem"}
                onChange={onChangeHandler}
                // placeholder={"패스워드를 입력해주세요."}
                // label={"패스워드"}
                required={""}
                textAlign={"center"}
                readOnly={false}
                inputStyleType="default"
                ref={null}
                // errMsg={errorMsg.password}
              /> */}
              <CheckBoxLabel fontWeight={700}>모두동의</CheckBoxLabel>
            </CheckBoxLabelWrapper>

            <CheckBoxWrapper>
              <CheckBoxLabelWrapper>
                {!formData.condition2 && (
                  <StyledCheckBoxUnChecked
                    onClick={() => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        condition2: true,
                      }));
                    }}
                  />
                )}
                {formData.condition2 && (
                  <StyledCheckBoxChecked
                    onClick={() => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        condition2: false,
                      }));
                    }}
                  />
                )}
                {/* <Input
                  type={"checkbox"}
                  name={"condition2"}
                  value={formData.condition2}
                  width={"2rem"}
                  height={"2rem"}
                  onChange={onChangeHandler}
                  required={""}
                  textAlign={"center"}
                  readOnly={false}
                  inputStyleType="default"
                  ref={null}
                  // errMsg={errorMsg.password}
                /> */}
                <CheckBoxLabel>개인정보처리방침</CheckBoxLabel>
              </CheckBoxLabelWrapper>
              <Link>자세히보기</Link>
            </CheckBoxWrapper>

            <CheckBoxWrapper>
              <CheckBoxLabelWrapper>
                {!formData.condition3 && (
                  <StyledCheckBoxUnChecked
                    onClick={() => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        condition3: true,
                      }));
                    }}
                  />
                )}
                {formData.condition3 && (
                  <StyledCheckBoxChecked
                    onClick={() => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        condition3: false,
                      }));
                    }}
                  />
                )}

                {/* <Input
                  type={"checkbox"}
                  name={"condition3"}
                  value={formData.condition3}
                  width={"2rem"}
                  height={"2rem"}
                  onChange={onChangeHandler}
                  required={""}
                  textAlign={"center"}
                  readOnly={false}
                  inputStyleType="default"
                  ref={null}
                  // errMsg={errorMsg.password}
                /> */}
                <CheckBoxLabel>서비스이용약관</CheckBoxLabel>
              </CheckBoxLabelWrapper>
              <Link>자세히보기</Link>
            </CheckBoxWrapper>
          </CheckBoxListWrapper>
        </Wrapper>
        <FormButton
          buttonText="회원가입"
          type={"disabled"}
          onClick={onFormHandler}
        />
      </Form>
      {isModal && <SignUpModal isOpen={isModal} />}
    </Wrapper>
  );
};

export default SignUpForm;

const CheckBoxListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1.2rem;
  /* justify-content: center;
  align-items: center;
  margin-top: 8.8rem; */
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CheckBoxLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.4rem;
  /* margin-top: 1.7rem;
  gap: 1.3rem; */
  /* justify-content: center;
  align-items: center;
  margin-top: 8.8rem; */
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 40rem; */
  gap: 4rem;
  padding: 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center;
  margin-top: 8.8rem; */
`;

const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center;
  margin-top: 8.8rem; */
`;

const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center;
  margin-top: 8.8rem; */
`;

const CheckBoxLabel = styled.label<{ fontWeight?: number }>`
  color: #4a5561;
  /* Body/Body_14_R */
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 142.857%; /* 20px*/
  letter-spacing: -0.2px;

  margin-left: 6px;
`;

const Link = styled.a`
  color: #acb5bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
  text-decoration-line: underline;
`;

const IdPwFinderWrapper = styled.div`
  margin-top: 4.8rem;
`;

export const StyledCheckBoxChecked = styled(CheckBoxChecked)`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;

export const StyledCheckBoxUnChecked = styled(CheckBoxUnChecked)`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;
